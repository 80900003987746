
import {mergeMap, map,  share, catchError } from 'rxjs/operators';
import { Injectable, Inject, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage' 

import { NotificationService } from "../shared/utils/notification.service";

import { MGISConstants } from '../common/constants/mgis.constants'; 

import { environment } from '../../environments/environment';
import { GroupBenefitsDashboardInfo } from '../models/dashboard-info.model';
import { GroupBenefitsClient } from '../models/group-benefits-client.model';
import { User } from '../models/user.model';
import { SecureHttp } from './securehttp';
import { SubscriberBase } from "app/common/base/subscriber.base";
import { ToastService } from 'app/common/services/toast.service';
import { BrokerFirm } from '../models/broker-firm.model';

import { TrackingService } from '../auth/tracking.service';
import { DashboardService } from 'app/dashboard/dashboard.service';
import { MembersService } from 'app/group-benefits/members/members.service';
import { Permission } from 'app/models/permission.model';
import { Permissions } from './permissions';

import { JwtService } from './jwt.service';
import { ConfigService } from 'app/admin/config/config.service';

declare var moment: any;
declare var JSON: any;

@Injectable()
export class AuthService extends SubscriberBase {

    // store the URL so we can redirect after logging in
    redirectUrl: string;

    private keys = this.mgisConstants.AuthKeys;
    private loggedIn = false;
    private currentUser: User = null;
    private idleTime = 0;
    private minutesLeft = 0;
    private isShowingTimeout: boolean = false;
    private selfObservable: Observable<User> = of(null);

    get userPermissions(): string[] {
        return this.store.get<string[]>(this.keys.PermissionsKey) || [];
    }

    constructor(
        private http: HttpClient,
        private mgisConstants: MGISConstants,
        private secureHttp: SecureHttp,
        private store: LocalStorageService,
        private router: Router,
        @Inject(DOCUMENT) private document: any,
        private toast: ToastService,
        private notificationService: NotificationService,
        private trackingService: TrackingService,
        private dashboardService: DashboardService,
        private membersService: MembersService,
        private jwtService: JwtService,
        private configService: ConfigService
    ) {
        super();
        this.loggedIn = !!store.get(this.keys.AuthKey) && this.isTokenValid();
        if (this.checkSessionTimeout()) {
            this.setSessionTimeout();
            setInterval(() => { this.checkSessionTimeout(); }, 60000); // 60000 - 1 minute
            this.document.addEventListener('click', (e) => {
                if (!this.isShowingTimeout) {
                    this.setSessionTimeout();
                    if (this.isLoggedIn()) {
                        this.trackingService.trackActivity();
                    }
                }
            });
        }

        if (this.loggedIn) {
            this.setSelfObservable();
        }
    }

    login(username, password) {

        this.currentUser = null;
        this.clearStore();

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

        let tokenUrl = 'token';
        let passwordEncoded = encodeURIComponent(password);
        let body = `username=${username}&password=${passwordEncoded}&grant_type=password&scope=openid email roles&grantType=password`;
        return this.http.post(tokenUrl, body, { headers }).pipe(

            map(response => {
                this.store.set(this.keys.AuthKey, response);
                this.loggedIn = true;
                this.setSelfObservable();

                this.store.remove(this.keys.ClientKey);
                this.store.remove(this.keys.ProducerKey);
                this.trackingService.trackLogin(username, true);

                let decodedIdToken = this.jwtService.decodeToken(response['id_token']);

                let permissions: string[] = Array.isArray(decodedIdToken.permission) ? decodedIdToken.permission : [decodedIdToken.permission];
                this.store.set(this.keys.PermissionsKey, permissions);

                return response['success'];
            }));
    }

    register(firstName, lastName, username, password, roles?: string[]) {

        this.currentUser = null;
        this.clearStore();

        let url = 'api/account/register';

        return this.http.post(url, { FirstName: firstName, LastName: lastName, Email: username, Password: password, Roles: roles }).pipe(

            mergeMap(() => {
                return this.login(username, password);
            }));
    }

    logout(returnUrl?: string) {
        if (this.loggedIn) {
            if (this.currentUser != null) {
                this.trackingService.trackLogout(this.currentUser.Email);
            }
            else {
                this.subscriptions.push(this.queryCurrentUser().subscribe(self => {
                    if (self) {
                        this.trackingService.trackLogout(self.Id);
                    }
                }));
            }
        }
        this.clearStore();
        this.currentUser = null;
        this.loggedIn = false;
        this.router.navigateByUrl(returnUrl || '/auth/login');
    }

    redirect(url?: string) {
        this.router.navigate([this.redirectUrl || url || '/dashboard']);
        setTimeout(() => {
            this.redirectUrl = null;
        }, 1000);
    }

    redirectAfterLogin(portal?: string) {
        this.subscriptions.push(this.queryCurrentUser().subscribe(self => {
            if (portal) {
                this.setSelectedPortal(portal).subscribe(() => {
                    this.redirectAfterPortalSelection();
                });
            }
            this.subscriptions.push(this.queryCurrentUserPortals().subscribe(
                (portals) => {
                    if (portals && portals.length > 1) {
                        this.redirect('/auth/select-portal');
                    } else if (portals && portals.length) {
                        this.setSelectedPortal(portals[0]).subscribe(() => {
                            this.redirectAfterPortalSelection();
                        });
                    } else {
                        this.redirect();
                    }
                }
            ));
        }));
    }

    redirectAfterPortalSelection() {
        this.getPortal().subscribe(portal => {
            if (portal == this.mgisConstants.Portals.GroupBenefits.Name) {
                this.queryCurrentUser().subscribe(self => {
                    if (this.isInRole(self, this.mgisConstants.Roles.MGISSuperUser) || this.isInRole(self, this.mgisConstants.Roles.MGISAccountManager) || self.Clients.length != 1) {
                        this.redirect('/auth/select-client');
                    } else {
                        this.setSelectedClient(self.Clients[0]).subscribe(() => {
                            this.redirect();
                        });

                    }
                });
            } else if (portal == this.mgisConstants.Portals.Producer.Name) {
                this.queryCurrentUser().subscribe(self => {
                    if (this.isInRole(self, this.mgisConstants.Roles.MGISSuperUser) || this.isInRole(self, this.mgisConstants.Roles.MGISBrokerServices) || self.BrokerFirms.length != 1) {
                        this.redirect('/auth/select-broker-firm');
                    } else {
                        this.setSelectedProducer(self.BrokerFirms[0]).subscribe(() => {
                            this.redirect();
                        });
                    }
                });
            } else {
                this.redirect();
            }
        });
    }

    isTokenValid(): boolean {

        let authData = this.store.get(this.keys.AuthKey);

        if (!authData) {
            return false;
        }

        // Add seconds until expiration
        let expiration = moment().add(authData[this.keys.ExpiresKey], 's');
        let diff = moment().diff(expiration);
        return diff < 0;
    }

    isLoggedIn(): boolean {
        return this.loggedIn && this.isTokenValid();
    }

    getToken(): string {
        let authData = this.store.get(this.keys.AuthKey);
        return authData[this.keys.AccessTokenKey];
    }

    queryCurrentUser(): Observable<User> {

        // if (!this.secureHttp.checkAuthToken()) //CAJ 05-09-2019 #19298 - added check for token expiration, wasn't covered and caused error under the covers
        // {
        //     this.router.navigate(['/auth/login']);
        //     return of(null);
        // }
        if(this.currentUser && this.currentUser.Permissions && !this.currentUser.Permissions.length && this.userPermissions && this.userPermissions.length) {
            this.currentUser.Permissions = this.userPermissions
        }

        if (this.currentUser != null) {
            return of(this.currentUser);
        }

        if (!this.loggedIn) {
            return of(null);
        }
        return this.selfObservable;
    }

    updateCurrentUser(user: User): Observable<User> {
        return this.secureHttp.put('api/users/self', user).pipe(
            map(response => response as User),
            map(self => {
                this.currentUser = self;
                return self;
            }),);
    }

    queryRoles(): Observable<string[]> {
        if (!this.loggedIn) {
            return of([]);
        }

        return this.secureHttp.get(`api/roles`).pipe(
            map(response => response['Data'] as string[]));
    }

    queryPermissions(): Observable<Permission[]> {
        if (!this.loggedIn) {
            return of([]);
        }

        return this.secureHttp.get(`api/roles/permissions`).pipe(
            map(response => response['Data'] as Permission[]));
    }

    queryCurrentUserRole(role: string): Observable<boolean> {
        if (!this.loggedIn) {
            return of(false);
        }

        return this.queryCurrentUser().pipe(map(self => {
            if (!self) {
                return false;
            }
            return this.isInRole(self, role);
        }));
    }

    isInRole(user, role) {
        return !!user.Roles.some(r => r.toLowerCase() == role.toLowerCase());
    }

    filterUserPermissions(permissions: string[], selectedClient: any, permissionValue: any) {
        let userPermissions = []
        if (permissions.length) {
            userPermissions = permissions.filter(
                (p) =>
                    p.indexOf('_') < 0 ||
                    (selectedClient && p.indexOf(selectedClient.AccountNum) >= 0)
            )
        }
        return userPermissions.every((p) => p.indexOf(permissionValue) == 0)
    }

    userHasPermission(permissionValue): boolean {
        const selectedClient = this.store.get<GroupBenefitsClient>(this.keys.ClientKey);
        return this.userPermissions.some(p => p == permissionValue || selectedClient && p == `${permissionValue}_${selectedClient.AccountNum}`);
    }

    userHasPermissionGroup(permissionGroup): boolean {
        return this.userPermissions.some(p => p != null && p.indexOf(permissionGroup) == 0);
    }

    userHasOnlyPermission(permissionValue): boolean {
        const selectedClient = this.store.get<GroupBenefitsClient>(this.keys.ClientKey);
        const userPermissions = this.userPermissions && this.userPermissions.length ? this.userPermissions : this.currentUser.Permissions;
        return this.filterUserPermissions(userPermissions, selectedClient, permissionValue)
    }

    queryGroups(): Observable<string[]> {
        return this.queryRoles().pipe(map(roles => {
            let groups: string[] = [];
            roles.forEach(r => {
                let group = r.split(' | ')[0].trim();
                this.addToList(groups, group);
            });
            return groups.sort((a, b) => a.localeCompare(b));
        }));
    }

    queryPortals(): Observable<string[]> {
        return this.queryRoles().pipe(map(roles => {
            let portals: string[] = [];
            roles.forEach(r => {
                let portal = r.split(' | ')[0].trim();
                let display = this.getPortalDisplayName(portal);
                this.addToGroupList(portals, portal, display);
            });
            return portals.sort((a, b) => a.localeCompare(b));
        }));
    }

    queryCurrentUserPortals(): Observable<string[]> {
        if (!this.loggedIn) {
            return of([]);
        }
        return this.queryCurrentUser().pipe(mergeMap(self => {
            return this.queryRoles().pipe(map(roles => {
                let portals: string[] = [];
                roles.filter(r => self.Roles.some(rr => rr == r)).forEach(r => {
                    let portal = r.split(' | ')[0].trim();
                    let display = this.getPortalDisplayName(portal);
                    this.addToGroupList(portals, portal, display);
                });
                // If user is super user, make sure all portals are available
                if (this.isInRole(self, this.mgisConstants.Roles.MGISSuperUser)) {
                    this.addToGroupList(portals, this.mgisConstants.Portals.GroupBenefits.Name, this.mgisConstants.Portals.GroupBenefits.Display);
                    this.addToGroupList(portals, this.mgisConstants.Portals.SurplusLines.Name, this.mgisConstants.Portals.SurplusLines.Display);
                    this.addToGroupList(portals, this.mgisConstants.Portals.Producer.Name, this.mgisConstants.Portals.Producer.Display);
                }

                // If user is MGIS account manager or has Group Benefits permissions, make sure Group Benefits portal is available
                if (this.isInRole(self, this.mgisConstants.Roles.MGISAccountManager)) {
                    this.addToGroupList(portals, this.mgisConstants.Portals.GroupBenefits.Name, this.mgisConstants.Portals.GroupBenefits.Display);
                }

                // If user is MGIS broker services, make sure Producer portal is available
                if (this.isInRole(self, this.mgisConstants.Roles.MGISBrokerServices)) {
                    this.addToGroupList(portals, this.mgisConstants.Portals.Producer.Name, this.mgisConstants.Portals.Producer.Display);
                }

                return portals.sort((a, b) => a.localeCompare(b));
            }));
        }));
    }

    addToList(list, item) {
        if (list.indexOf(item) < 0) {
            list.push(item);
        }
    }

    addToGroupList(list, item, displayText) {
        if (list.indexOf(displayText || item) < 0) {
            list.push(displayText || item);
        }
    }

    getPortalName(display) {
        let portalKey = Object.keys(this.mgisConstants.Portals).find(k => this.mgisConstants.Portals[k].Display == display);
        return portalKey && this.mgisConstants.Portals[portalKey].Name || display;
    }

    getPortalDisplayName(name) {
        let portalKey = Object.keys(this.mgisConstants.Portals).find(k => this.mgisConstants.Portals[k].Name == name);
        return portalKey && this.mgisConstants.Portals[portalKey].Display || name;
    }

    // changeGroupNameIfFound(list, item, displayText) {
    //     //Logic:
    //     // - if the item to check is not found then no worries can exit
    //     // - if the displayTexxt to change it to is already there then don't want to add it so return
    //     // - else if found the item text change it to the displayText
    //     if ((list.indexOf(item) < 0) || (list.indexOf(displayText) >= 0)) {
    //         return;
    //     }
    //     else
    //     {
    //         var i = list.indexOf(item);
    //         if (i >= 0)
    //         {
    //             list[i] = displayText; //update the text to display the proper captions
    //         }
    //     }
    // }

    queryEmailUnique(userId: string, email: string) {
        let params = new HttpParams();
        params = params.set('userId', userId);
        params = params.set('email', email);
        return this.http.get(`api/auth/unique-email`, { params }).pipe(
            map(response => response['Data'] as boolean));
    }

    queryPasswordUnique(email: string, password: string) {
        let params = new HttpParams()
        params = params.set('email', email)
        params = params.set('password', password);
        return this.http.get(`api/auth/unique-password`, { params }).pipe(
            map(response => response['Data'] as boolean));
    }

    recoverPassword(username: string, surplusLinesToken: string): Observable<boolean> {

        return this.http.post('api/auth/forgotpassword',
            { EmailAddress: username, SurplusLinesToken: surplusLinesToken }).pipe(
            map(response => true));
    }

    getResetToken(): Observable<string> {
        return this.secureHttp.get('api/auth/password-reset-token').pipe(
            map(response => response['Data'] as string));
    }

    checkResetToken(email: string, token: string): Observable<boolean> {
        let params = new HttpParams()
        params = params.set('email', email)
        params = params.set('token', token);
        return this.http.get('api/auth/password-reset-token/validate', { params }).pipe(
            map(response => response as boolean));
    }

    setPassword(email: string, token: string, password: string): Observable<boolean> {
        return this.http.post('api/auth/password',
            { Email: email, token: token, password: password }).pipe(
            map(response => {
                if (this.currentUser && this.currentUser.MustResetPassword) {
                    this.currentUser.MustResetPassword = false;
                }
                return true;
            }));
    }

    phoneVerified(email: string, phone: string): Observable<boolean> {
        return this.http.post('api/users/verifyphone', {
            email: email,
            phone: phone
        }).pipe(
            map(response => true));
    }

    getPortal(): Observable<string> {
        return of(this.store.get(this.keys.PortalKey));
    }

    getSelectedClient(): Observable<GroupBenefitsClient> {
        if (!this.loggedIn) {
            return of(null);
        }
        var client = this.store.get(this.keys.ClientKey) as GroupBenefitsClient;

        //return of(this.store.get(this.keys.ClientKey) as Client);
        return of(client);
    }

    getSelectedProducer(): Observable<BrokerFirm> {
        if (!this.loggedIn) {
            return of(null);
        }
        return of(this.store.get(this.keys.ProducerKey) as BrokerFirm);
    }

    getSurplusLinesAccount(): Observable<string> {
        if (!this.loggedIn) {
            return of(null);
        }

        return of(this.store.get(this.keys.SurplusLinesAccountKey));
    }

    setSelectedClient(client: GroupBenefitsClient, redirect: boolean = true): Observable<boolean> {
        this.store.set(this.keys.ClientKey, client);

        // Get hashed account number
        let params = new HttpParams();
        params = params.set('account', client.AccountNum);
        this.secureHttp.get('api/auth/client', { params: params }).subscribe(response => {
            this.store.set(this.keys.ClientHashKey, response);
        });

        this.dashboardService.getGroupBenefitsInfo(client.AccountNum, true).subscribe();
        if (this.currentUser.Permissions.indexOf(Permissions.GroupBenefits.Members.View) >= 0) {
            this.membersService.refresh(client.AccountNum).subscribe();
        }

        if (redirect) {
            this.redirect();
        }

        return of(true);
    }

    setSelectedPortal(portal: string, redirect: boolean = true): Observable<boolean> {
        if (portal == this.mgisConstants.Portals.MedTravel.Name) {
            portal = this.mgisConstants.Portals.SurplusLines.Name;
        }
        this.store.set(this.keys.PortalKey, this.getPortalName(portal));

        if (portal == this.mgisConstants.Portals.SurplusLines.Display) {
            let params = new HttpParams();
            params = params.set('refresh', 'true');

            // refresh surplus lines reference data
            let refUrl = 'api/surplus-lines/reference';
            this.subscriptions.push(this.secureHttp.get(refUrl, { params: params }).subscribe());

            // refresh dashboard info
            let infoUrl = `api/dashboard/surplus-lines/info`;
            this.subscriptions.push(this.secureHttp.get(infoUrl).pipe(
                map((info) => {
                    this.store.set(this.keys.SurplusLinesAccountKey, info['AccountNum']);
                })).subscribe());
        }

        if (redirect) {
            this.redirectAfterPortalSelection();
        }

        return of(true);
    }

    setSelectedProducer(producer: BrokerFirm, redirect: boolean = true): Observable<boolean> {
        this.store.set(this.keys.ProducerKey, producer);

        let params = new HttpParams();
        params = params.set('agentNum', producer.FirmNum);

        // refresh dashboard info and account-specific options
        let infoUrl = `api/dashboard/producer/refresh`;
        this.subscriptions.push(this.secureHttp.get(infoUrl, { params: params }).subscribe());

        if (redirect) {
            this.redirect();
        }

        return of(true);
    }

    protected clearStore() {
        Object.keys(this.keys).map(k => this.keys[k]).concat(this.store.keys().filter(k => k.startsWith("newPayment"))).forEach(key => {
            this.store.remove(key);
        });
    }

    private checkSessionTimeout(): boolean {
        let timeout = this.store.get(this.keys.SessionTimeoutKey);
        if (this.isLoggedIn() && moment().isSameOrAfter(timeout)) {
            if (this.isShowingTimeout) {
                this.isShowingTimeout = false;
                this.notificationService.closeSmartMessageBox();
            }
            this.forceLogout();
            return false;
        } else if (this.isLoggedIn() && moment().isSameOrAfter(moment(timeout).subtract(2, 'minutes'))) {
            if (!this.isShowingTimeout) {
                this.displayTimeout();
            }
            return false;
        }
        return true;
    }

    private displayTimeout() {
        if (!this.isShowingTimeout) {
            this.isShowingTimeout = true;
            var msg = `Due to inactivity, you will be logged out<span id="Countdown"> in a few minutes</span>. Would you like to continue this session?`;
            this.notificationService.smartMessageBox({
                title: `<i class='fa fa-clock-o txt-color-primary'></i> Session Ending`,
                content: msg,
                buttons: '[Continue Session][Exit]',
            }, (ButtonPressed) => {
                if (ButtonPressed == "Continue Session") {
                    this.setSessionTimeout();
                    this.isShowingTimeout = false;
                    this.notificationService.closeSmartMessageBox();
                }
                else {
                    this.forceLogout();
                }
            });
            this.setCountdownText();
        }
    }
    private forceLogout() {
        this.logout();
        this.isShowingTimeout = false;
        this.notificationService.closeSmartMessageBox();
    }

    private setSessionTimeout() {
        this.configService.getConfig(true).subscribe(config => {
            let timeoutSeconds = parseInt(config.timeoutSeconds) || 1200;
            this.store.set(this.keys.SessionTimeoutKey, moment().add(timeoutSeconds, 'seconds'));
        })
    }

    private setCountdownText() {
        let timeout = this.store.get(this.keys.SessionTimeoutKey);
        let diff = moment.duration(moment(timeout).diff(moment()));
        if (diff.asSeconds() > 0) {
            let minutes = diff.minutes();
            let seconds = diff.seconds();
            $('#Countdown').text(` in ${minutes > 0 ? ('about ' + diff.humanize()) : 'a few seconds'}`);
            setTimeout(() => this.setCountdownText(), 1000);
        } else {
            $('#Countdown').text(` in a few seconds`);
        }
    }

    private setSelfObservable() {
        this.selfObservable = this.secureHttp.get('api/users/self').pipe(
            map(response => response as User),
            map(self => {
                if (self.Active) {
                    this.currentUser = self;
                    return self;
                } else {
                    this.currentUser = null;
                    return null;
                }
                
            }), catchError(err => {
                this.router.navigate(['/auth/login']);
                return of(null);
            }))
    }
}