import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'app/dashboard/dashboard.service';

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  copy = new Date();
  buildNumber: string;
  buildURI: string;

  constructor(
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.dashboardService.getBuildNumber().subscribe(buildNumber=>this.buildNumber = buildNumber);
    this.dashboardService.getBuildURI().subscribe(buildURI=>this.buildURI = buildURI);
  }

}
