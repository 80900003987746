import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


import {
  TooltipModule, 
} from 'ngx-bootstrap/tooltip'

import { AlertModule } from "ngx-bootstrap/alert";

import { PopoverModule } from "ngx-smart-popover";

import { SmartadminLayoutModule } from './layout'

import { SmartadminWidgetsModule } from "./widgets/smartadmin-widgets.module";

import { UtilsModule } from "./utils/utils.module";

@NgModule({
  imports: [
    CommonModule, 
    FormsModule, 
    HttpClientModule, 
    RouterModule,
  ],
  declarations: [
  ],
  exports: [
    CommonModule, 
    FormsModule, 
    HttpClientModule, 
    RouterModule,
    AlertModule,
    TooltipModule,
    PopoverModule,
    SmartadminLayoutModule,
    UtilsModule,
    SmartadminWidgetsModule,
  ]
})
export class SmartadminModule {
  static forRoot(): ModuleWithProviders<SmartadminModule> {
    return {
      ngModule: SmartadminModule,
      providers: []
    };
  }
}
