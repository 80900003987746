// jQuery
// Commented out to allow fallback to @types/jquery
// By Matt 3/27/17
//declare var jQuery: any;

// RxJS




// Smartadmin Dependencies
window['jQuery'] = require('jquery');
window['$'] = window['jQuery'];
import 'jquery-ui-npm/jquery-ui.min.js'

// require('bootstrap/js/tooltip.js'); // required for X-editable
// require('bootstrap/js/popover.js'); // required for X-editable
// require('bootstrap/js/dropdown.js'); // required for bootstrap-colorpicker
// require('bootstrap/js/tab.js'); //
// require('bootstrap/js/modal.js'); //

window['moment'] = require('moment');

window['underscore'] = require('underscore');
window['_'] = window['underscore'];

//import 'jQuery=jquery' //'imports-loader?jQuery=jquery!jquery-color/jquery.color.js'

require('assets/js/SmartNotification.js');
require('jquery-mask-plugin');

window['shortid'] = require('shortid');
window['shortId'] = window['shortid'];

// window['publicip'] = require('public-ip');

