
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { SecureHttp } from 'app/auth/securehttp';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class StripeService {

    constructor(private secureHttp: SecureHttp) { }

    getSetupIntent(): Observable<any> {

        return this.secureHttp.get('api/stripe/setup')
            ;
    }

    getPaymentIntent(amount: number, paymentMethodId: string, confirm = false): Observable<any> {
        let params = new HttpParams()
        params = params.set('amount', amount.toString())
        params = params.set('paymentMethodId', paymentMethodId)
        params = params.set('confirm', confirm.toString())

        return this.secureHttp.get('api/stripe/payment', {
          params: {
            amount: amount,
            paymentMethodId: paymentMethodId,
            confirm: confirm,
          },
        })
    }

    cancelPaymentIntent(id: string): Observable<any> {
        let params = new HttpParams();
        params = params.set('id', id);
        return this.secureHttp.delete('/api/stripe/payment', { params: params });
    }

    getCreditCards(): Observable<any[]> {
        return this.secureHttp.get('/api/stripe/cards').pipe(
            map(response => response as any[]))
    }

    deleteCreditCard(paymentMethodId: string): Observable<any> {
        let params = new HttpParams();
        params = params.set('paymentMethodId', paymentMethodId);
        return this.secureHttp.delete('/api/stripe/cards', { params: params });
    }
}