import {Injectable} from '@angular/core';

declare var $: any;

@Injectable()
export class NotificationService {

  constructor() {
  }

  smallBox(data, cb?) {
    $.smallBox(data, cb)
  }

  bigBox(data, cb?) {
    $.bigBox(data, cb)
  }

  smartMessageBox(data, cb?) {
    $.SmartMessageBox(data, cb);
    $(".divMessageBox").show();
    if(data.input == 'text' && data.mask){
        $('#txt1').mask(data.mask);
    }
  }

  closeSmartMessageBox() {
    $(".divMessageBox").hide();
  }

}
