
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { SecureHttp } from "../../auth/securehttp";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { HttpParams } from "@angular/common/http";
import { ProducerLicensesAppointments } from "../../models/producer-licenses-appointments.model";


@Injectable()
export class ProducerLicensesAppointmentsService {

    constructor(
        private http: SecureHttp
    ) {
    }

    getLicensesAppointments(agentNum: string): Observable<ProducerLicensesAppointments> {
        let url = `api/producer/licenses-appointments`
        let params = new HttpParams();
        params = params.set('agentNum', agentNum);
        return this.http.get(url, { params: params }).pipe(map(response => response as ProducerLicensesAppointments))
    }
}