import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
import { NgModule, ApplicationRef } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageModule, LocalStorageService } from 'angular-2-local-storage';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CoreModule } from './core/core.module';
import { SmartadminLayoutModule } from './shared/layout';
import { SmartadminModule } from './shared/smartadmin.module';
import { routing } from './app.routing';
import { AdminGuard, AuthGuard, BillingGuard, BrokerGuard, GroupBenefitsAdminGuard, NotSelfAdminGuard,
  SurplusLinesPolicyOwnerGuard, SurplusLinesApplicantGuard, ProducerAdminGuard, ConfigGuard, PermissionGuard, RoleGuard
} from './auth/guards/auth.guard';
import { AuthService } from './auth/auth.service';
import { CoveragesService } from './group-benefits/coverages/coverages.service';
import { DashboardService } from './dashboard/dashboard.service';
import { GlobalInfoService } from './common/services/global-info.service';
import { SurplusLinesOpportunitiesService } from './surplus-lines/opportunities/opportunities.service';
import { MembersService } from './group-benefits/members/members.service';
import { SecureHttp } from './auth/securehttp';
import { ToastService } from './common/services/toast.service';
import { MGISCommonModule } from './common/common.module';
import { TrackingService } from './auth/tracking.service';
import { JwtService } from './auth/jwt.service';
import { ProducerLicensesAppointmentsService } from './producer/licenses-appointments/licenses-appointments.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {MobileServiceService} from './common/services/mobile-service.service';


// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    LocalStorageModule.withConfig({
      prefix: '',
      storageType: 'localStorage'
    }),

    BsDropdownModule.forRoot(),
    CoreModule,
    SmartadminLayoutModule,
    SmartadminModule.forRoot(),

    routing
  ],
  exports: [
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    // APP_PROVIDERS,

    AdminGuard,
    AuthGuard,
    ConfigGuard,
    AuthService,
    JwtService,
    BillingGuard,
    BrokerGuard,
    PermissionGuard,
    RoleGuard,
    CoveragesService,
    DashboardService,
    GlobalInfoService,
    GroupBenefitsAdminGuard,
    SurplusLinesOpportunitiesService,
    LocalStorageService,
    MembersService,
    NotSelfAdminGuard,
    SurplusLinesPolicyOwnerGuard,
    ProducerAdminGuard,
    SecureHttp,
    SurplusLinesApplicantGuard,
    ToastService,
    TrackingService,
    ProducerLicensesAppointmentsService, 
    MobileServiceService
  ]
})
export class AppModule {
  constructor(
    public appRef: ApplicationRef,
    // public appState: AppState
  ) { }


}

