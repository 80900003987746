import { Injectable } from '@angular/core';

import { CanActivate, CanActivateChild, CanLoad, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';

import { AuthService } from '../auth.service';

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let url: string = state.url;
        return this.isInRole(route);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): boolean {

        return true;
    }

    isInRole(route: ActivatedRouteSnapshot): boolean {

        if (!route.data || !route.data.roles) {
            return true;
        }

        let allowed = route.data.roles.some(async (p) => await this.authService.queryCurrentUserRole(p));

        if (allowed) {
            return true;
        }

        this.router.navigate(['/auth/login']);

        return false;
    }
}
