
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Address } from "app/models/address.model";
import { SecureHttp } from "app/auth/securehttp";
import { Permission } from "app/models/permission.model";
import {HttpParams} from "@angular/common/http";

@Injectable()
export class PermissionsService {

    constructor(private secureHttp: SecureHttp) {
    }

    getPermissions(options?) {
        let params = new HttpParams();
        if (options) {
          for (let key in options) {
            if (key && options[key]) {
              params = params.set(key, options[key])
            }
          }
        }
        return this.secureHttp.get(`api/permissions`, { params: params }).pipe(
            map(response => response as Permission[]));
    }
}