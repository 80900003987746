/**
 * Created by griga on 7/11/16.
 */


import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import {
    AdminGuard, AuthGuard, BillingGuard, GroupBenefitsAdminGuard, SurplusLinesApplicantGuard, ProducerAdminGuard,
    NotSelfAdminGuard, SurplusLinesPolicyOwnerGuard, MedTravelApplicantGuard, ConfigGuard, PermissionGuard, RoleGuard
} from './auth/guards/auth.guard';

import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { ExternalLayoutComponent } from './shared/layout/app-layouts/external-layout.component';
import { Permissions } from './auth/permissions';
import { MGISConstants } from './common/constants/mgis.constants';
import { Roles } from './auth/roles';

export const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            {
                path: '', redirectTo: 'dashboard', pathMatch: 'full'
            },
            {
                path: 'bank-accounts',
                loadChildren: () => import('app/bank-accounts/bank-accounts.module').then(m => m.BankAccountsModule),
                data: {
                    pageTitle: 'Manange Bank Accounts',
                    permissions: [
                        Permissions.GroupBenefits.Billing.Payments,
                        Permissions.Producers.Billing.Payments,
                        Permissions.SurplusLines.Policies.Manage
                    ]
                },
                canActivate: [PermissionGuard]
            },
            {
                path: 'billing',
                loadChildren: () => import('app/billing/billing.module').then(m => m.BillingModule),
                data: {
                    pageTitle: 'Billing',
                    permissions: [
                        Permissions.GroupBenefits.Billing.Statements,
                        Permissions.GroupBenefits.Billing.Payments,
                        Permissions.Producers.Billing.Statements,
                        Permissions.Producers.Billing.Payments,
                        Permissions.SurplusLines.Policies.Manage
                    ]
                },
                canActivate: [PermissionGuard]
            },
            {
                path: 'dashboard',
                loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
                data: { pageTitle: 'Dashboard' },
                canActivate: [AuthGuard]
            },
            {
                path: 'enrollments',
                loadChildren: () => import('app/enrollment-statistics/enrollment-statistics.module').then(m => m.EnrollmentStatisticsModule),
                data: { pageTitle: 'Enrollment Statistics' },
                canActivate: [AdminGuard]
            },
            {
                path: 'documents',
                loadChildren: () => import('app/documents/documents.module').then(m => m.DocumentsModule),
                data: { pageTitle: 'Documents' }
            },
            {
                path: 'payments',
                loadChildren: () => import('app/payments/payments.module').then(m => m.PaymentsModule),
                data: {
                    pageTitle: 'Payment History',
                    permissions: [
                        Permissions.GroupBenefits.Billing.Payments,
                        Permissions.Producers.Billing.Payments,
                        Permissions.SurplusLines.Policies.Manage
                    ]
                },
                canActivate: [PermissionGuard]
            },
            {
                path: 'preferences',
                loadChildren: () => import('app/preferences/preferences.module').then(m => m.PreferencesModule),
                data: { pageTitle: 'Manage Preferences' },
            },
            {
                path: 'release-notes',
                loadChildren: () => import('app/release-notes/release-notes.module').then(m => m.ReleaseNotesModule),
                data: { pageTitle: 'Release Notes' },
                canActivate: [AdminGuard]
            },


            // Admin
            {
                path: 'admin/clients',
                loadChildren: () => import('app/admin/clients/clients.module').then(m => m.ClientsModule),
                data: { pageTitle: 'Clients' },
                canActivate: [AdminGuard]
            },
            {
                path: 'admin/logs',
                loadChildren: () => import('app/admin/logs/logs.module').then(m => m.LogsModule),
                data: { pageTitle: 'Logs' },
                canActivate: [AdminGuard]
            },
            {
                path: 'admin/broker-firms',
                loadChildren: () => import('app/admin/producers/producers.module').then(m => m.ProducersModule),
                data: { pageTitle: 'Producers' },
                canActivate: [AdminGuard]
            },
            {
                path: 'admin/questionnaire-template',
                loadChildren: () => import('app/admin/questionnaire-template/questionnaire-template.module').then(m => m.QuestionnaireTemplateModule),
                data: { pageTitle: 'Templates' },
                canActivate: [AdminGuard]
            },
            {
                path: 'admin/users',
                loadChildren: () => import('app/admin/users/users.module').then(m => m.UsersModule),
                data: { pageTitle: 'Users' },
                canActivate: [AdminGuard]
            },
            {
                path: 'admin/roles',
                loadChildren: () => import('app/admin/roles/roles.module').then(m => m.RolesModule),
                data: { pageTitle: 'Roles' },
                canActivate: [AdminGuard]
            },
            {
                path: 'admin/activity',
                loadChildren: () => import('app/admin/activity/activity.module').then(m => m.ActivityModule),
                data: { pageTitle: 'Activity' },
                canActivate: [AdminGuard]
            },
            {
                path: 'admin/config',
                loadChildren: () => import('app/admin/config/config.module').then(m => m.ConfigModule),
                data: { pageTitle: 'Config' },
                canActivate: [AdminGuard]
            },
            {
                path: 'admin/documents',
                loadChildren: () => import('app/admin/documents/documents.module').then(m => m.DocumentsModule),
                data: { pageTitle: 'Documents', roles: [Roles.MGISAdmin.SuperUser] },
                canActivate: [RoleGuard]
            },

            // Group Benefits
            {
                path: 'group-benefits/members',
                loadChildren: () => import('app/group-benefits/members/members.module').then(m => m.MembersModule),
                data: { pageTitle: 'Members', permissions: [Permissions.GroupBenefits.Members.View] },
                canActivate: [PermissionGuard]
            },
            {
                path: 'group-benefits/users',
                loadChildren: () => import('app/group-benefits/users/users.module').then(m => m.GroupBenefitsUsersModule),
                data: { pageTitle: 'Members', permissions: [Permissions.GroupBenefits.Users.Manage] },
                canActivate: [PermissionGuard]
            },

            // Surplus Lines
            {
                path: 'surplus-lines/invitations',
                loadChildren: () => import('app/surplus-lines/invitations/invitations.module').then(m => m.SurplusLinesInvitationsModule),
                data: { pageTitle: 'Invitations' },
            },
            {
                path: 'surplus-lines/opportunities',
                loadChildren: () => import('app/surplus-lines/opportunities/opportunities.module').then(m => m.SurplusLinesOpportunitiesModule),
                data: { pageTitle: 'Opportunities' },
            },
            {
                path: 'surplus-lines/policies',
                loadChildren: () => import('app/surplus-lines/policies/policies.module').then(m => m.SurplusLinesPoliciesModule),
                data: { pageTitle: 'Policies' },
                canActivate: [SurplusLinesPolicyOwnerGuard]
            },
            // {
            //   path: 'surplus-lines/quotes',
            //   loadChildren: () => import('app/surplus-lines/quotes/quotes.module').then(m => m.SurplusLinesQuotesModule),
            //   data: {pageTitle: 'Quotes'},
            // //   canActivate: [SurplusLinesBrokerGuard]
            // },
            {
                path: 'surplus-lines/profile',
                loadChildren: () => import('app/surplus-lines/user-profile/user-profile.module').then(m => m.SurplusLinesUserProfileModule),
                data: { pageTitle: 'User Profile' },
                canActivate: [SurplusLinesApplicantGuard]
            },

            // Producers
            {
                path: 'broker-firm/producers',
                loadChildren: () => import('app/producer/agents/agents.module').then(m => m.ProducerAgentsModule),
                data: { pageTitle: 'Producers', permissions: [Permissions.Producers.Agents.View] },
                canActivate: [PermissionGuard]
            },
            {
                path: 'broker-firm/commission-statements',
                loadChildren: () => import('app/producer/commission-statements/commission-statements.module').then(m => m.ProducerCommissionStatementsModule),
                data: { pageTitle: 'Commission Statements', permissions: [Permissions.Producers.CommissionStatements.View] },
                canActivate: [PermissionGuard]
            },
            {
                path: 'broker-firm/tax-documents',
                loadChildren: () => import('app/producer/tax-documents/tax-documents.module').then(m => m.ProducerTaxDocumentsModule),
                data: { pageTitle: 'Tax Documents', permissions: [Permissions.Producers.TaxDocuments.View] },
                canActivate: [PermissionGuard]
            },
            {
                path: 'broker-firm/users',
                loadChildren: () => import('app/producer/users/users.module').then(m => m.ProducerUsersModule),
                data: { pageTitle: 'User Management', permissions: [Permissions.Producers.Users.Manage] },
                canActivate: [PermissionGuard]
            },


            // med travel
            {
                path: 'med-travel/application',
                loadChildren: () => import('app/med-travel/application/application.module').then(m => m.MedTravelApplicationModule),
                data: { pageTitle: 'MedTravel Application' },
                canActivate: [ConfigGuard]
            },
            {
                path: 'med-travel/policy-request',
                loadChildren: () => import('app/med-travel/policy-request/policy-request.module').then(m => m.MedTravelPolicyRequestModule),
                data: { pageTitle: 'MedTravel Policy Request' },
                canActivate: [ConfigGuard]
            },
        ]
    },

    {
        path: 'group-benefits/application',
        component: ExternalLayoutComponent,
        loadChildren: () => import('app/group-benefits/application/application.module').then(m => m.GroupBenefitsApplicationModule),
    },

    {
        path: 'surplus-lines',
        component: ExternalLayoutComponent,
        loadChildren: () => import('app/surplus-lines/surplus-lines.module').then(m => m.SurplusLinesModule),
        // canActivate: [ConfigGuard]
    },

    {
        path: 'med-travel',
        component: ExternalLayoutComponent,
        loadChildren: () => import('app/med-travel/med-travel.module').then(m => m.MedTravelModule),
        canActivate: [ConfigGuard]
    },

    {
        path: 'auth',
        component: ExternalLayoutComponent,
        loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule)
    },

    {
        path: 'release',
        component: ExternalLayoutComponent,
        loadChildren: () => import('app/release-notes/new-release/new-release.module').then(m => m.NewReleaseModule),
    },

    {
        path: 'miscellaneous',
        component: ExternalLayoutComponent,
        loadChildren: () => import('app/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule)
    },

    { path: '**', redirectTo: 'miscellaneous/error/404' }

];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' });
