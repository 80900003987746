
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class GlobalInfoService {

    private badges = {};
    private seenBadges = [];
    private info = [];
    private badgesSubject = new Subject<any>();
    private infoSubject = new Subject<any[]>();

    

    setInfo(info: any[]) {
        this.info = info;
        this.broadcastInfo();
    }

    insertInfo(info: any, index: number) {
        this.info.splice(index, 0, info);
        this.broadcastInfo();
    }

    pushInfo(info: any) {
        this.info.push(info);
        this.broadcastInfo();
    }

    unshiftInfo(info: any) {
        this.info.unshift(info);
        this.broadcastInfo();
    }

    removeInfo(title: string) {
        this.info = this.info.filter(i => i.Title != title);
        this.broadcastInfo();
    }

    clearInfo() {
        this.info = [];
        this.broadcastInfo();
    }

    getInfo(): Observable<any> {
        return this.infoSubject.asObservable();
    }

    setBadge(item: string, badge: string, force?: boolean){
        this.badges[item] = badge;
        if(force && this.seenBadges.indexOf(item) > -1){
            this.seenBadges.splice(this.seenBadges.indexOf(item), 1);
        }
        this.brodcastBadges();
    }

    getBadge(item: string): Observable<string>{
        return this.badgesSubject.asObservable().pipe(
            map(badges=>badges[item] as string));
    }

    getBadges(): Observable<string>{
        return this.badgesSubject.asObservable();
    }

    markBadgeSeen(item: string){
        this.seenBadges.push(item);
        this.brodcastBadges();
    } 

    clearBadge(item: string){
        this.badges[item] = null;
        this.brodcastBadges();
    }

    clearBadges(){
        this.badges = {};
        this.seenBadges = [];
        this.brodcastBadges();
    }



    private brodcastBadges(){
        this.seenBadges.forEach(b=>this.badges[b] = null);
        this.badgesSubject.next(this.badges);
    }

    private broadcastInfo() {
        this.infoSubject.next(this.info);
    }
}