import { Injectable } from "@angular/core";

@Injectable()
export class RegexConstants{
    Date = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
    Email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    HasDigit = /[0-9]/;
    HasNonLetterOrDigit = /[^A-Za-z0-9]/;
    HasLowercase = /[a-z]/;
    HasUppercase = /[A-Z]/;
    Phone = /^\(\d{3}\) \d{3}-\d{4}((\sext\.)?\s\d{1,4})?$/;
    Url = /^([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    
    DateLiteral = '^[0-9]{2}\\/[0-9]{2}\\/[0-9]{4}.?';
    EmailLiteral = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
    PhoneLiteral = '^\\(\\d{3}\\) \\d{3}-\\d{4}(\\s\\d{4})?$';
}