
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';


@Injectable()
export class FileService {

    downloadDocument(documentResponse) {
        console.info('Downloading document...');
        const docBody = documentResponse;
        const fileName = docBody.Extension != null && docBody.FileName.indexOf(docBody.Extension) < 0 ? (docBody.FileName + '.' + docBody.Extension) : docBody.FileName;
        const blob = this.base64toBlob(docBody.Content || docBody.Content2, docBody.MIMEType);
        console.info('Created blob from ' + fileName);
        try {
            saveAs(blob, fileName);
        }
        catch (err) {
            console.error('Error downloading ' + fileName + ': ' + JSON.stringify(err));
            window.navigator.msSaveBlob(blob, fileName);
        }
    }

    private base64toBlob(base64Data, contentType = '', sliceSize = 512) {
        console.info('Decoding base 64 data...');
        try {
            const byteCharacters = window.atob(base64Data.replace(/[\n\r]/g, ''));

            console.info('Decoded base 64 data');
            const byteArrays = [];

            console.info('Decoding data (' + byteCharacters.length + ' bytes)...')
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                console.debug('Decoding slice ' + (offset + 1) + '...');
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            console.info('Creating blob...');
            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        } catch (error) {
            console.error('Could not decode base 64 data: ' + error);
        }
    }
}