
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { SecureHttp } from '../auth/securehttp'
import { Observable, Subject, BehaviorSubject ,  from, of } from 'rxjs'


import {
  GroupBenefitsDashboardInfo,
  SurplusLinesDashboardInfo,
  ProducerDashboardInfo,
} from '../models/dashboard-info.model'
import { MGISConstants } from 'app/common/constants/mgis.constants'
import { SubmitDocumentsModel } from 'app/models/submit-documents.model'

@Injectable()
export class DashboardService {
  public groupBenefitsInfo: any = {} // BehaviorSubject<GroupBenefitsDashboardInfo>
  public producerInfo: any = {} // BehaviorSubject<GroupBenefitsDashboardInfo>

  constructor(
    private http: HttpClient,
    private mgisConstants: MGISConstants,
    private secureHttp: SecureHttp
  ) { }

  getGroupBenefitsInfo(
    account?: string,
    refresh?: boolean
  ): BehaviorSubject<GroupBenefitsDashboardInfo> {
    if (refresh || !this.groupBenefitsInfo[account]) {
      this.groupBenefitsInfo[account] =
        new BehaviorSubject<GroupBenefitsDashboardInfo>(null)
      this.groupBenefitsInfo[account].subscribe((info) =>
        console.log('Group benefits info is: ' + info)
      )

      let url = `api/dashboard/group-benefits/info`
      let params = new HttpParams()
      if (account) {
        params = params.set('account', account)
        if (refresh) {
          params = params.set('refresh', 'true')
        }
        this.secureHttp.get(url, { params: params }).subscribe((response) => {
          const info = response as GroupBenefitsDashboardInfo
          this.groupBenefitsInfo[account].next(info)
        })
      }
    }

    return this.groupBenefitsInfo[account]
  }

  queryGroupBenefitsInfo(
    account: string
  ): Observable<GroupBenefitsDashboardInfo> {
    let url = `api/dashboard/group-benefits/info`
    let params = new HttpParams();
    params = params.set('account', account);
    return this.secureHttp
      .get(url, { params: params }).pipe(
      map((response) => response as GroupBenefitsDashboardInfo))
  }

  getProducerInfo(
    agentNum?: string,
    refresh?: boolean
  ): Observable<ProducerDashboardInfo> {
    if (refresh || !this.producerInfo[agentNum]) {
      this.producerInfo[agentNum] =
        new BehaviorSubject<GroupBenefitsDashboardInfo>(null)
      this.producerInfo[agentNum].subscribe((info) =>
        console.log('Producer info is: ' + info)
      )

      let url = `api/dashboard/producer/info`
      let params = new HttpParams()
      if (agentNum) {
        params = params.set('agentNum', agentNum)
        if (refresh) {
          params = params.set('refresh', 'true')
        }
        this.secureHttp.get(url, { params: params }).subscribe((response) => {
          const info = response as ProducerDashboardInfo
          this.producerInfo[agentNum].next(info)
        })
      }
    }

    return this.producerInfo[agentNum]
  }

  getSurplusLinesInfo(
    refresh?: boolean
  ): Observable<SurplusLinesDashboardInfo> {
    let url = `api/dashboard/surplus-lines/info`
    let params = new HttpParams()
    if (refresh) {
      params = params.set('refresh', 'true')
    }
    return this.secureHttp
      .get(url, { params: params }).pipe(
      map((response) => response as SurplusLinesDashboardInfo))
  }

  getBuildNumber(): Observable<string> {
    let url = `api/dashboard/buildNumber`
    return this.http.get(url).pipe(map((response) => response['Data']))
  }

  getBuildURI(): Observable<string> {
    let url = `api/dashboard/buildURI`
    return this.http.get(url).pipe(map((response) => response['Data']))
  }

  getInspirationalQuote(): Observable<any> {
    let idx = Math.floor(Math.random() * this.mgisConstants.Quotes.length)
    return of(this.mgisConstants.Quotes[idx])
  }

  submitDocuments(documents: SubmitDocumentsModel): Observable<string> {
    let url = `api/documents/submit-documents`
    return this.http.post(url, documents).pipe(map((response) => response.toString()))
  }

  getAccountManager(accountNum: string) {
    let url = `api/dashboard/manager`
    let params = new HttpParams()
    if (accountNum) {
      params = params.set('accountNum', accountNum)
    }
    return this.secureHttp
      .get(url, { params: params }).pipe(
      map((response) => response))
  }

  uploadSharePoint(documents: SubmitDocumentsModel): Observable<Promise<string>> {
    let url = `api/documents/sharepoint-upload`
    if (documents.SharePointToken) {
      return this.secureHttp.post(url, documents).pipe(map((res) => res))
    }
  }
}
