
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Inject, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage'

import { NotificationService } from "../shared/utils/notification.service";

import { MGISConstants } from '../common/constants/mgis.constants';
import { SubscriberBase } from "app/common/base/subscriber.base";
import { environment } from '../../environments/environment';
import { SecureHttp } from './securehttp';
import { UserSession } from "../models/usersession.model";
import { PagedResults } from 'app/models/paged-results.model';

declare var moment: any;
declare var JSON: any;

@Injectable()
export class TrackingService extends SubscriberBase {

    constructor(
        private http: HttpClient,
        private mgisConstants: MGISConstants,
        private secureHttp: SecureHttp,
        private store: LocalStorageService,
        private router: Router) {
        super();
    }

    trackActivity() {
        this.secureHttp.post('api/tracking/activity', {}).subscribe();
    }

    trackLogin(userName, success, failedReason?) {
        let sess = new UserSession();
        sess.UserName = userName;
        sess.LoginSuccess = success;
        sess.LoginFailedReason = failedReason;

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let url = 'api/tracking/login';
        this.http.post(url, sess, { headers }).pipe(
            map(res => res),
            catchError((error: any) => observableThrowError(error.error || 'Server error')))
            .subscribe();
    }

    trackLogout(userName) {
        let sess = new UserSession();
        sess.UserName = userName;

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        let url = 'api/tracking/logout';
        this.http.post(url, sess, { headers }).pipe(
            map(res => res),
            catchError((error: any) => observableThrowError(error.error || 'Server error')))
            .subscribe();
    }

    queryUserSessions(options?: any): Observable<PagedResults<UserSession>> {
        let url = 'api/tracking/sessions';

        let params = new HttpParams();
        if (options) {
            Object.keys(options).forEach(key => {
                if (options[key]) {
                    params = params.set(key, options[key].toString());
                }
            });
        }

        return this.secureHttp.get(url, { params: params }).pipe(
            map(response => response as PagedResults<UserSession>));
    }

    queryCurrentUserSessions(options?: any): Observable<PagedResults<UserSession>> {
        options = options || {};
        options.current = true;
        return this.queryUserSessions(options);
    }

    queryFailedUserSessions(options?: any): Observable<PagedResults<UserSession>> {
        options = options || {};
        options.start = options.start || moment().subtract(30, 'd').format('MM/DD/YYYY');
        options.failed = true;
        return this.queryUserSessions(options);
    }
}