import { Component, OnInit, HostListener } from '@angular/core';
import { LayoutService } from "../../layout.service";
import { Router, NavigationEnd } from '@angular/router';
import { SubscriberBase } from 'app/common/base/subscriber.base';

declare var $: any;

@Component({
  selector: 'sa-collapse-menu',
  templateUrl: './collapse-menu.component.html'
})
export class CollapseMenuComponent extends SubscriberBase implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layoutService.onCollapseMenu(false);
  }

  constructor(
    private layoutService: LayoutService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.layoutService.onCollapseMenu(false);
      }
    }));
  }

  onToggle() {
    this.layoutService.onCollapseMenu()
  }
}
