
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Address } from "app/models/address.model";
import { SecureHttp } from "app/auth/securehttp";

@Injectable()
export class AddressesService {

    constructor(private secureHttp: SecureHttp) {
    }

    getAddresses() {
        return this.secureHttp.get(`api/addresses`).pipe(
            map(response => response as Address[]));
    }

    createAddress(address: Address) {
        return this.secureHttp.post(`api/addresses`, address)
            ;
    }

    deleteAddress(id: number) {
        return this.secureHttp.delete(`api/addresses/${id}`);
    }
}