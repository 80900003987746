import { Subscription } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';

// TODO: Add Angular decorator.
@Directive()
export class SubscriberBase implements OnDestroy {

    protected subscriptions: Subscription[] = [];

    constructor() { }

    ngOnDestroy() {
        // prevent memory leak by unsubscribing
        this.subscriptions.filter(s=>!s.closed).forEach(s=>s.unsubscribe());
    }
}

