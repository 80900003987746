
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class IPService {
  constructor(private http: HttpClient) {}

  getIP(): Observable<string> {
    let params = new HttpParams()
    params = params.set('format', 'json')
    return this.http
      .get('https://api.ipify.org', { params: params }).pipe(
      map((response: any) => {
        return response.ip
      }))
  }
}
