
import {map, mergeMap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { SecureHttp } from "../../auth/securehttp";
import { SurplusLinesOpportunity, SurplusLinesOpportunityDetail } from "../../models/surplus-lines-opportunity.model";
import { environment } from "environments/environment";
import { SurplusLinesTokenValidation } from "../../models/surplus-lines-token-validation.model";
import { SurplusLinesDocument, SurplusLinesDocumentUpload } from "../../models/surplus-lines-document.model";
import { SurplusLinesPlanDesign, SurplusLinesPlanDesignsRequest } from "../../models/surplus-lines-plan-design.model";
import { SurplusLinesPaymentPlan } from "../../models/surplus-lines-payment-plan.model";
import { SurplusLinesQuestionnaire } from "../../models/surplus-lines-questionnaire.model";
import { SurplusLinesApplicant } from "../../models/surplus-lines-applicant.model";
import { SurplusLinesRegistration } from "../../models/surplus-lines-registration.model";
import { SurplusLinesDocumentUploadResponse } from "../../models/surplus-lines-document-upload-response.model";
import { SurplusLinesOpportunitySnapshot } from "../../models/surplus-lines-opportunity-snapshot.model";
import { StatusResponse } from "../../models/status-response.model";
import { Observable } from "rxjs";

declare var moment: any;

@Injectable()
export class SurplusLinesOpportunitiesService {

    constructor(
        private http: HttpClient,
        private secureHttp: SecureHttp
    ) {

    }

    query(options?: any): Observable<SurplusLinesOpportunity[]> {

        let url = 'api/surplus-lines/opportunities';
        let params: HttpParams = new HttpParams();

        if (options) {
          for (let key in options) {
            if (key && options[key]) {
              params = params.set(key, options[key])
            }
          }
        }

        return this.secureHttp.get(url, { params: params }).pipe(
            map(response => response as SurplusLinesOpportunity[]));
    }

    get(id: number): Observable<SurplusLinesOpportunityDetail> {
        let url = 'api/surplus-lines/opportunities/' + id;
        return this.secureHttp.get(url).pipe(
            map(response => response as SurplusLinesOpportunityDetail));
    }

    latest(): Observable<SurplusLinesOpportunityDetail> {
        let url = 'api/surplus-lines/opportunities/latest';
        return this.secureHttp.get(url).pipe(
            map(response => response as SurplusLinesOpportunityDetail));
    }

    checkState(stateCode: string): Observable<boolean> {
        let url = 'api/surplus-lines/opportunities/state/' + stateCode;

        return this.secureHttp.get(url).pipe(
            map(response => response.Data as boolean));
    }

    checkAge(birthDate: string): Observable<boolean> {
        let url = 'api/surplus-lines/opportunities/age/' + moment(birthDate).format('MM-DD-YYYY');

        return this.secureHttp.get(url).pipe(
            map(response => response.Data as boolean));
    }

    validate(token: string): Observable<SurplusLinesTokenValidation> {
        let url = 'api/surplus-lines/token/validate';

        let params = new HttpParams();
        params = params.set('token', token);

        return this.http.get(url, { params: params }).pipe(
            map(response => response as SurplusLinesTokenValidation));
    }

    getUsedTokens(email: string): Observable<string[]> {
        let url = `api/surplus-lines/tokens/used`;

        let params = new HttpParams();
        params = params.set('email', email);

        return this.http.get(url, { params: params }).pipe(
            map((response: any) => response.Data as string[]))
    }

    getDocuments(opportunityId: number | string) {
        let url = `api/surplus-lines/opportunities/${opportunityId}/documents`;
        return this.secureHttp.get(url).pipe(
            map(response => response as SurplusLinesDocument[]));
    }

    getPlanDesigns(opportunityId: number, request: SurplusLinesPlanDesignsRequest): Observable<SurplusLinesPlanDesign[]> {
        if (request != null && request.InsuredId != null) {
            let url = `api/surplus-lines/opportunities/${opportunityId}/plan-designs`;
            return this.secureHttp.post(url, request).pipe(
                map(response => response as SurplusLinesPlanDesign[]));
        }
    }

    getInstallmentPlan(opportunityId: number): Observable<SurplusLinesPaymentPlan> {
        let url = `api/surplus-lines/opportunities/${opportunityId}/installment-plan`;

        return this.secureHttp.get(url).pipe(
            map(response => response as SurplusLinesPaymentPlan));
    }

    getQuestionnaire(questionnaireId: string): Observable<SurplusLinesQuestionnaire> {
        let url = `api/surplus-lines/opportunities/questionnaire/${questionnaireId}`;
        return this.secureHttp.get(url).pipe(
            map(response => response as SurplusLinesQuestionnaire));
    }

    accept(model: SurplusLinesApplicant) {
        let url = 'api/surplus-lines/token/use';

        return this.secureHttp.post(url, model);
    }

    createAccount(model: SurplusLinesRegistration): Observable<SurplusLinesOpportunity> {
        let url = 'api/surplus-lines/token/create-account';

        return this.http.post(url, model).pipe(map(response => response as SurplusLinesOpportunity));
    }

    selectPlanDesign(insuredId: number, opportunityId: number, planDesignId: number, ptdElectedBenefit: number, ttdElectedBenefit: number) {
        let url = 'api/surplus-lines/opportunities/plan-designs/select';

        return this.secureHttp.put(url, { InsuredId: +insuredId, OpportunityId: opportunityId, PlanDesignId: planDesignId, PTDElectedBenefit: ptdElectedBenefit, TTDElectedBenefit: ttdElectedBenefit });
    }

    getPlanDesignRating(insuredId: number, opportunityId: number, planDesignId: number, ptdElectedBenefit: number, ttdElectedBenefit: number) {
        let url = 'api/surplus-lines/opportunities/plan-designs/rating';

        return this.secureHttp.put(url, { InsuredId: insuredId, OpportunityId: opportunityId, PlanDesignId: planDesignId, PTDElectedBenefit: ptdElectedBenefit, TTDElectedBenefit: ttdElectedBenefit }).pipe(
            map(response => response as SurplusLinesPlanDesign));
    }

    saveDocument(opportunityId: number, document: SurplusLinesDocument): Observable<SurplusLinesDocumentUploadResponse> {
        let url = `api/surplus-lines/opportunities/${opportunityId}/documents/save`;

        return this.secureHttp.post(url, document).pipe(
            map(response => response as SurplusLinesDocumentUploadResponse));
    }

    uploadDocument(file: File, opportunityId: number, documentId: number, documentTypeCode: string): Observable<SurplusLinesDocumentUploadResponse> {

        let reader = new FileReader();

        let url = `api/surplus-lines/opportunities/${opportunityId}/documents`;

        let model = {
            DocumentId: documentId,
            DocumentTypeCode: documentTypeCode,
            FileName: file.name.split('.')[0],
            Extension: file.name.split('.')[1]
        } as SurplusLinesDocumentUpload

        return Observable.create(observer => {

            let responses = [];

            reader.onload = () => {
                observer.next(reader.result);
                observer.complete();
            };

            reader.onerror = () => {
                observer.error(`An error occurred while uploading file "${file.name}"`);
            }

            reader.readAsDataURL(file);

        }).pipe(mergeMap(content => {
            model.Content = content as string;
            return this.secureHttp.post(url, model);
        })).map(response => response as SurplusLinesDocumentUploadResponse);
    }

    selectPaymentPlan(opportunityId: number, paymentPlanId: number): Observable<SurplusLinesPaymentPlan> {
        let url = `api/surplus-lines/opportunities/${opportunityId}/payment-plans/${paymentPlanId}`;

        return this.secureHttp.get(url).pipe(
            map(response => response as SurplusLinesPaymentPlan))
    }

    sendApplication(snapshot: SurplusLinesOpportunitySnapshot, complete: boolean, partA: boolean): Observable<StatusResponse> {
        let url = `api/surplus-lines/opportunities/${snapshot.OpportunityId}/applications/${complete}`;

        let params = new HttpParams();
        params = params.set('complete', complete.toString());
        params = params.set('partA', partA.toString());

        return this.secureHttp.post(url, snapshot, { params: params }).pipe(
            map(response => response as StatusResponse))
    }

    submit(snapshot: SurplusLinesOpportunitySnapshot): Observable<StatusResponse> {
        let url = `api/surplus-lines/opportunities/${snapshot.OpportunityId}/submit`;

        return this.secureHttp.post(url, snapshot).pipe(
            map(response => response as StatusResponse))
    }

}
