import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lastfour' })
export class LastFourPipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return value;
		}
		let length = value.length;
		if (length <= 4) {
			return value;
		}
		let retVal = value.substr(length - 4, 4);
		while (retVal.length < length) {
			retVal = '•' + retVal;
		}
		return retVal;
	}
}