
import {map, mergeMap} from 'rxjs/operators';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { DashboardService } from '../../dashboard/dashboard.service';

@Injectable()
export class NotSelfAdminGuard implements CanActivate {

    constructor(private authService: AuthService, private dashboardService: DashboardService, private router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let url: string = state.url;

        return this.checkNotSelfAdmin(url);
    }

    checkNotSelfAdmin(url: string): Observable<boolean> {

        if (this.authService.isLoggedIn()) {

            return this.authService.getSelectedClient().pipe(mergeMap(client => {
                return this.dashboardService.getGroupBenefitsInfo(client.AccountNum).pipe(map(info => info && !info.SelfAdmin));
            }));
        }

        this.authService.redirectUrl = url;

        this.router.navigate(['/auth/login']);
        return of(false);
    }
}
