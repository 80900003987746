
import {map} from 'rxjs/operators';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigService } from 'app/admin/config/config.service';

@Injectable()
export class ConfigGuard implements CanActivate {

    constructor(
        private configService: ConfigService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let url: string = state.url;

        return this.checkConfig(url);
    }

    checkConfig(url: string): Observable<boolean> {

        if (url.includes('/med-travel')) {
            return this.configService
                .getConfig(true).pipe(
                map(config => {
                    let allowed = config.AllowMedTravel;
                    if (allowed) {
                        return true;
                    }
                    this.router.navigateByUrl('/miscellaneous/error404');
                    return false;
                }));
        } else if (url.includes('/surplus-lines/quick-estimate')) {
            return this.configService
                .getConfig(true).pipe(
                map(config => {
                    let allowed = config.AllowSurplusLinesQuickEstimate;
                    if (allowed) {
                        return true;
                    }
                    this.router.navigateByUrl('/miscellaneous/error404');
                    return false;
                }));
        }

        return of(true);

    }
}
