<header id="header">
  <div id="logo-group">

    <!-- PLACE YOUR LOGO HERE -->
    <span id="logo" class="click-me"
      routerLink="{{isGroupBenefitsPortal ? '/auth/select-client' : isProducerPortal ? '/auth/select-broker-firm' : '/'}}"
      *ngIf="isGroupBenefitsPortal && !!selectedClient && !!selectedClient.LogoUrl || isProducerPortal && !!selectedProducer && !!selectedProducer.LogoUrl">
      <img src="{{isGroupBenefitsPortal ? selectedClient.LogoUrl : selectedProducer.LogoUrl}}"
        alt="{{isGroupBenefitsPortal ? selectedClient.Name : selectedProducer.Name}}"> </span>
    <h2 class="theme-font txt-color-primary click-me"
      routerLink="{{isGroupBenefitsPortal ? '/auth/select-client' : isProducerPortal ? '/auth/select-broker-firm' : '/'}}"
      *ngIf="isGroupBenefitsPortal && !!selectedClient && !selectedClient.LogoUrl || isProducerPortal && !!selectedProducer && !selectedProducer.LogoUrl"
      [class.smaller]="isGroupBenefitsPortal ? selectedClient.Name.length > 30 : selectedProducer.Name?.length > 30">
      <b>{{(isGroupBenefitsPortal ? accountName || selectedClient.Name : selectedProducer.Name || selectedProducer.FirmNum) | uppercase}}</b>
    </h2>
    <span id="logo" class="click-me"
      routerLink="{{isGroupBenefitsPortal ? '/auth/select-client' : isProducerPortal ? '/auth/select-broker-firm' : '/'}}"
      *ngIf="isGroupBenefitsPortal && !selectedClient || isProducerPortal && !selectedProducer || !isGroupBenefitsPortal && !isProducerPortal">
      <img src="assets/img/mgis/logo.png" alt="MGIS NOW All-Access Portal"> </span>
    <!-- END LOGO PLACEHOLDER -->

    <!-- Note: The activity badge color changes when clicked and resets the number to 0
    Suggestion: You may want to set a flag when this happens to tick off all checked messages / notifications -->
  </div>


  <div id="action-group">
    <!-- pulled right: profile area -->
    <!-- <span id="login-info" class="pull-right hidden-xs" *ngIf="currentUser">
    <a *ngIf="currentUser.Clients && currentUser.Clients.length > 1" routerLink="/auth/select-client" title="{{selectedClient?.Name}}">Client: {{selectedClient?.Name}}</a>
    <span *ngIf="currentUser.Clients && currentUser.Clients.length > 1">|</span>
    <a (click)="logout()">Logout</a>
    </span>
    <span id="login-info" class="pull-right hidden-xs" *ngIf="!currentUser">
    <a href="javascript:void(0);">Help</a> | <a routerLink="/auth/login">Login</a>
  </span> -->
    <!-- end pulled right: profile area -->

    <!-- pulled right: nav area -->
    <div class="pull-right clear-right">

      <sa-collapse-menu></sa-collapse-menu>

      <!-- #MOBILE -->
      <!-- Top menu profile link : this shows only when top menu is active -->
      <!--<ul id="mobile-profile-img" class="header-dropdown-list hidden-xs padding-5">
      <li class="">
        <a href="#" class="dropdown-toggle no-margin userdropdown" data-toggle="dropdown">
          <img src="assets/img/avatars/sunny.png" alt="John Doe" class="online"/>
        </a>
        <ul class="dropdown-menu pull-right">
          <li>
            <a (click)="(null)" class="padding-10 padding-top-0 padding-bottom-0"><i
              class="fa fa-cog"></i> Setting</a>
          </li>
          <li class="divider"></li>
          <li>
            <a routerLink="/app-views/profile" class="padding-10 padding-top-0 padding-bottom-0"> <i
              class="fa fa-user"></i>
              <u>P</u>rofile</a>
          </li>
          <li class="divider"></li>
          <li>
            <a (click)="(null)" class="padding-10 padding-top-0 padding-bottom-0"
               data-action="toggleShortcut"><i class="fa fa-arrow-down"></i> <u>S</u>hortcut</a>
          </li>
          <li class="divider"></li>
          <li>
            <a (click)="(null)" class="padding-10 padding-top-0 padding-bottom-0"
               data-action="launchFullscreen"><i class="fa fa-arrows-alt"></i> Full <u>S</u>creen</a>
          </li>
          <li class="divider"></li>
          <li>
            <a routerLink="/auth/login" class="padding-10 padding-top-5 padding-bottom-5" data-action="userLogout"><i
              class="fa fa-sign-out fa-lg"></i> <strong><u>L</u>ogout</strong></a>
          </li>
        </ul>
      </li>
    </ul>-->


      <!-- search mobile button (this is hidden till mobile view port) -->
      <!--<div id="search-mobile" class="btn-header transparent pull-right">
      <span> <a (click)="toggleSearchMobile()" title="Search"><i class="fa fa-search"></i></a> </span>
    </div>-->
      <!-- end search mobile button -->

      <!-- input: search field -->
      <!-- <div class="header-search pull-right hidden-xs" [ngClass]="{zipped: !searchActive}">
        <input id="search-fld" type="text" name="param" placeholder="{{searchPlaceholder}}" (keyup.enter)="toggleSearch(true)"
          (blur)="toggleSearch()" />
        <button type="button" (click)="toggleSearch(true)">
          <i class="fa fa-search"></i>
        </button>
      </div> -->
      <!-- end input: search field -->

      <!-- fullscreen button -->
      <!--<sa-full-screen></sa-full-screen>-->
      <!-- end fullscreen button -->

      <!-- select portal button -->
      <div id="select-portal" class="btn-header pull-right" *ngIf="showPortalSelection">
        <span>
          <a routerLink="/auth/select-portal" title="Select Portal">
            <i class="fa fa-laptop"></i>
          </a>
        </span>
      </div>
      <!-- end select portal button -->

      <!-- select client button -->
      <div id="select-client" class="btn-header pull-right" *ngIf="isGroupBenefitsPortal ">
        <span>
          <a routerLink="/auth/select-client" title="Select Client">
            <i class="fa fa-briefcase"></i>
          </a>
        </span>
      </div>
      <!-- end select client button -->

      <!-- select producer button -->
      <div id="select-producer" class="btn-header pull-right" *ngIf="canSelectFirm">
        <span>
          <a routerLink="/auth/select-broker-firm" title="Select Broker Firm">
            <i class="fa fa-briefcase"></i>
          </a>
        </span>
      </div>
      <!-- end select producer button -->

      <!-- logout button -->
      <sa-logout></sa-logout>
      <!-- end logout button -->

      <!-- multiple lang dropdown : find all flags in the flags page -->
      <!--<sa-language-selector></sa-language-selector>-->
      <!-- end multiple lang -->



    </div>
    <!-- end pulled right: nav area -->
  </div>
</header>