
import {map,  tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { SecureHttp } from '../../auth/securehttp';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { PagedResults } from '../../models/paged-results.model';
import { MemberUpdate } from '../../models/member-update.model';
import { Change } from '../../models/change.model';
import { StatusResponse } from '../../models/status-response.model';
import { SelectOption } from '../../models/select-option.model';
import { HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { MGISConstants } from 'app/common/constants/mgis.constants';
import { Member, MemberEdit, DuplicateMember } from 'app/models/member.model';
import { FileService } from '../../common/services/file.service';

@Injectable()
export class MembersService {

    uploader = new FileUploader({
        authToken: 'Bearer ' + ((this.store.get(this.mgisConstants.AuthKeys.AuthKey) || {})[this.mgisConstants.AuthKeys.AccessTokenKey] || '')
    });

    constructor(
        private http: SecureHttp,
        private mgisConstants: MGISConstants,
        private store: LocalStorageService,
        private fileService: FileService
    ) { }

    query(options?: any): Observable<Member[]> {
        let url = 'api/members';
        let params: HttpParams = new HttpParams();

        if (options) {
            for (let key in options) {
                if (key && options[key]) {
                    params = params.set(key, options[key]);
                }
            }
        }

        return this.http.get(url, { params: params }).pipe(
            map(response => response as Member[]));
    }

    refresh(accountNum: string): Observable<Member[]> {
        let url = 'api/members/refresh';
        let params = new HttpParams()
        params = params.set('account', accountNum)
        return this.http.get(url, { params: params }).pipe(
            map(response => response as Member[]));
    }

    paged(options?: any): Observable<PagedResults<Member>> {

        let url = 'api/members/paged';
        let params: HttpParams = new HttpParams();

        if (options) {
            for (let key in options) {
                if (key && options[key]) {
                    params = params.set(key, options[key]);
                }
            }
        }

        return this.http.get(url, { params: params }).pipe(
            map(response => response as PagedResults<Member>));
    }

    get(id: string, account: string): Observable<Member> {

        let url = `api/members/${id}`;
        let params = new HttpParams();
        params = params.set('account', account);
        return this.http.get(url, { params: params }).pipe(
            map(response => response as Member));
    }

    getUpdates(options?: any): Observable<MemberUpdate[]> {

        let url = 'api/members/updates';
        let params: HttpParams = new HttpParams();

        if (options) {
            for (let key in options) {
                if (key && options[key]) {
                    params = params.set(key, options[key]);
                }
            }
        }

        return this.http.get(url, { params: params }).pipe(
            map(response => response as MemberUpdate[]));
    }

    getUpdatesPaged(options?: any): Observable<PagedResults<MemberUpdate>> {

        let url = 'api/members/updates/paged';
        let params: HttpParams = new HttpParams();

        if (options) {
            for (let key in options) {
                if (key && options[key]) {
                    params = params.set(key, options[key]);
                }
            }
        }

        return this.http.get(url, { params: params }).pipe(
            map(response => response as PagedResults<MemberUpdate>));
    }

    getUpdate(account: string, id: string): Observable<MemberUpdate> {

        let url = 'api/members/update';
        let params: HttpParams = new HttpParams();
        params = params.set('account', account);
        params = params.set('id', id);

        return this.http.get(url, { params: params }).pipe(
            map(response => response as MemberUpdate));
    }

    getUpdateDetails(id: string): Observable<Change[]> {

        let url = 'api/members/update/details';
        let params: HttpParams = new HttpParams();
        params = params.set('id', id);

        return this.http.get(url, { params: params }).pipe(
            map(response => response as Change[]));
    }

    cancelUpdate(id: string): Observable<StatusResponse> {

        let url = `api/members/update`;

        let params: HttpParams = new HttpParams();
        params = params.set('id', id);

        return this.http.delete(url, { params: params }).pipe(map(response => response as StatusResponse));
    }

    create(member: MemberEdit, account: string): Observable<StatusResponse> {

        let url = 'api/members';

        let params: HttpParams = new HttpParams();
        params = params.set('account', account);

        return this.http.post(url, member, { params: params }).pipe(map(response => response as StatusResponse));
    }

    update(member: MemberEdit, account: string): Observable<StatusResponse> {

        let url = `api/members`;

        let params: HttpParams = new HttpParams();
        params = params.set('account', account);

        return this.http.put(url, member, { params: params }).pipe(map(response => response as StatusResponse));
    }

    updateSalaries(members: { Members: Member[] }, account: string): Observable<StatusResponse> {

        let url = `api/members/salary`;

        let params: HttpParams = new HttpParams();
        params = params.set('account', account);

        return this.http.put(url, members, { params: params }).pipe(map(response => response as StatusResponse));
    }

    terminate(member: MemberEdit, account: string): Observable<StatusResponse> {

        let url = `api/members/terminate`;

        let params: HttpParams = new HttpParams();
        params = params.set('account', account);

        return this.http.put(url, member, { params: params }).pipe(map(response => response as StatusResponse));
    }

    checkDuplicate(options?: any): Observable<DuplicateMember> {
        let url = `api/members/duplicate`;
        let params: HttpParams = new HttpParams();

        if (options) {
            for (let key in options) {
                if (key && options[key]) {
                    params = params.set(key, options[key]);
                }
            }
        }

        return this.http.get(url, { params: params }).pipe(
            map(response => response as DuplicateMember));
    }

    typeahead(account: string, filter: string, status?: string): Observable<SelectOption[]> {
        let url = `api/members/typeahead`;
        let params: HttpParams = new HttpParams();
        params = params.set('account', account);
        params = params.set('filter', filter);
        if (status) {
            params = params.set('status', status);
        }
        return this.http.get(url, { params: params }).pipe(
            map(response => response as SelectOption[]));
    }

    census(account: string, salary: boolean, type: string, format: string): Observable<any> {
        let url = `api/documents/census/download`;
        let params = new HttpParams();
        params = params.set('account', account);
        params = params.set('salary', salary.toString());
        params = params.set('type', type);
        params = params.set('format', format);
        return this.http.get(url, { params: params }).pipe(tap((response) => {
            this.fileService.downloadDocument(response);
        }));
    }

    download(ids: string[], accountNum: string): Observable<any> {
        let url = `api/members/download`;
        let params = new HttpParams();
        params = params.set('acountNumber', accountNum)
        params = params.set('ids', ids.join(','));
        return this.http.get(url, { params: params }).pipe(tap((response) => {
            this.fileService.downloadDocument(response);
        }));

    }

    upload(file: File, account: string): Observable<StatusResponse> {

        this.uploader.options.url = `api/members/upload?account=${account}`;

        return Observable.create(observer => {

            this.uploader.onCompleteItem = (data, response) => {
                if (data.isSuccess) {
                    observer.next({ Status: '0', Message: response } as StatusResponse);
                    observer.complete();
                } else {
                    observer.error(JSON.parse(response).Message);
                }
            };

            this.uploader.onErrorItem = (data, response) => {
                let error = JSON.parse(response);
                observer.error(`${error.Message} ${error.ExceptionMessage}`);
            };

            this.uploader.addToQueue([file]);
            this.uploader.uploadAll();

        });
    }
}
