import {NgModule} from "@angular/core";
import {WidgetComponent} from "./widget/widget.component";
import {WidgetsGridComponent} from "./widgets-grid/widgets-grid.component";
import {CommonModule} from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

@NgModule({
  imports: [CommonModule],
  declarations: [WidgetComponent, WidgetsGridComponent],
  exports: [WidgetComponent, WidgetsGridComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
  
})
export class SmartadminWidgetsModule{}
