import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { SubscriberBase } from "app/common/base/subscriber.base";
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ConfigService } from './admin/config/config.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent extends SubscriberBase implements OnInit {

  appTitle: string = 'MGIS Now';

  public constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private configService: ConfigService,
    private router: Router,
    private title: Title,
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      if (data && data.pageTitle) {
        this.title.setTitle(`${data.pageTitle} | ${this.appTitle}`);
      } else {
        this.title.setTitle(this.appTitle);
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');

        this.authService.queryCurrentUser().subscribe(user => {
          (<any>window).ga('send', 'event', {
            eventCategory: 'User Page View',
            eventLabel: event.url,
            eventAction: `"${event.url}" viewed by: ${user ? user.Email : "Not Logged In"}`
          });
        });
      }
    });
    this.configService.getConfig(true).subscribe();
  }

}
