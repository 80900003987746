import { Injectable } from "@angular/core";

@Injectable()
export class AzureConstants {
    LogosContainerName = "logos";
    AdminDocumentsContainerName = "admin-documents";
    SurplusLinesFormsContainerName = "iphl-forms";

    AccessType = {
        Off: 0,
        Container: 1,
        Blob: 2,
        Unknown: 3
    };
}