import { throwError as observableThrowError, Observable } from 'rxjs'
import { ApplicationRef, Injectable } from '@angular/core'
import { Location } from '@angular/common'
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Router } from '@angular/router'

import { LocalStorageService } from 'angular-2-local-storage'
import { ToastService } from '../common/services/toast.service'
import { catchError, finalize } from 'rxjs/operators'

declare var $: any

class ValidationError {
  Key: string
  Value: string
}

class RequestOptions {
  body?: any
  observe?: 'body'
  headers?: HttpHeaders
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>
      }
  responseType?: 'json' | 'arraybuffer' | 'blob' = 'json'
}

@Injectable()
export class SecureHttp {
  constructor(
    private app: ApplicationRef,
    private http: HttpClient,
    private location: Location,
    private router: Router,
    private store: LocalStorageService,
    private toast: ToastService
  ) {
    let authData = store.get('authData')

    if (authData) {
      if (authData.hasOwnProperty('access_token')) {
        var token = authData['access_token']
        let headers = new HttpHeaders()
        headers = headers.set('Authorization', `Bearer ${token}`)
      }
    }
  }

  checkAuthToken() {
    var tokenExists: boolean = false
    let authData = this.store.get('authData')
    if (authData) {
      if (authData.hasOwnProperty('access_token')) {
        var token = authData['access_token']
        if (token != undefined) {
          tokenExists = true
        }
      }
    }
    return tokenExists
  }

  request(
    method: string,
    url: string,
    options?: RequestOptions
  ): Observable<any> {
    let authData = this.store.get('authData')
    let token = authData && authData['access_token']

    options = options || {}

    options.headers = options.headers || new HttpHeaders()
    options.headers = options.headers.set('Authorization', `Bearer ${token}`)
    options.headers = options.headers.set('Cache-Control', 'no-cache')

    options.params = options.params || new HttpParams()

    return this.http
      .request(method, url, options as any)
      .pipe(catchError(this.catchAuthError(this)))
  }

  get(url: string, options?: RequestOptions): Observable<any> {
    return this.request('GET', url, options).pipe(
      finalize(() => {
        // IE hack
        if (!!document['documentMode']) {
          setTimeout(() => {
            this.app.tick()
          }, 2000)
        }
      })
    )
  }

  post(url: string, body: any, options?: RequestOptions): Observable<any> {
    if (!options && Object.keys(body).length > 0) {
      options = {}
      options.body = body
    }
    if (
      options &&
      body && 
      Object.keys(options).length > 0 &&
      Object.keys(body).length > 0
    ) {
      options.body = body
    }

    return this.request('POST', url, options).pipe(
      finalize(() => {
        // IE hack
        if (!!document['documentMode']) {
          setTimeout(() => {
            this.app.tick()
          }, 2000)
        }
      })
    )
  }

  put(url: string, body: any, options?: RequestOptions): Observable<any> {
    if (!options && Object.keys(body).length > 0) {
      options = {}
      options.body = body
    }
    if (
      options &&
      Object.keys(options).length > 0 &&
      Object.keys(body).length > 0
    ) {
      options.body = body
    }
    return this.request('PUT', url, options).pipe(
      finalize(() => {
        // IE hack
        if (!!document['documentMode']) {
          setTimeout(() => {
            this.app.tick()
          }, 2000)
        }
      })
    )
  }

  delete(url: string, options?: RequestOptions): Observable<any> {
    return this.request('DELETE', url, options).pipe(
      finalize(() => {
        // IE hack
        if (!!document['documentMode']) {
          setTimeout(() => {
            this.app.tick()
          }, 2000)
        }
      })
    )
  }

  private catchAuthError(self: SecureHttp) {
    // we have to pass HttpService's own instance here as `self`
    return (res: HttpErrorResponse) => {
      if (!res.url || res.url.indexOf('api/users/self') > 0) {
        return observableThrowError(res)
      }

      if (res.status == 400 && res) {
        // if (typeof (res) === 'string') {
        //     const msg = res.error.startsWith('{') ? JSON.parse(res)[''][0] : res;
        //     this.toast.error(msg, 'Validation Error');
        // } else {

        let errors = res.error

        var message =
          (errors.map && errors.map((e) => e.Value || e).join('<br />')) ||
          errors.Message

        this.toast.error(message, 'Validation Error')
        // }

        // $.bigBox({
        //     title: "Application Error ",
        //     content: message,
        //     color: "#C46A69",
        //     icon: "fa fa-warning shake animated",
        //     //number: "1",
        //     timeout: 10000
        // });
      }

      if (res.status === 401 || res.status === 403) {
        // if not authenticated
        this.toast.message(
          'You must be logged in to complete this action',
          'Not Logged In'
        )

        this.router.navigate(['/auth/login'], {
          queryParams: { returnUrl: this.location.path() },
        })

        // $.bigBox({
        //     title: "Not Authenticated!",
        //     content: "You must be logged in to complete this action.",
        //     color: "#C46A69",
        //     icon: "fa fa-warning shake animated",
        //     //number: "1",
        //     timeout: 10000
        // });
      }

      return observableThrowError(res)
    }
  }
}
