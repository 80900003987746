import { Injectable } from '@angular/core';

import { RegexConstants } from '../constants/regex.constants';

import { AuthService } from '../../auth/auth.service';

declare var moment: any;

@Injectable()
export class ValidationService {

    public validatedEmail: any = {};
    public validEmail: any = {};

    public validatedPhone: any = {};
    public validPhone: any = {};

    constructor(
        private authService: AuthService,
        private regexConstants: RegexConstants
    ) { }

    validateAddress(obj: any, required?: boolean): boolean {

        let hasAddress = !! obj.Address1 || !!obj.AddressLine1
            || !! obj.Address2 || !!obj.AddressLine2
            || !!obj.City || !!obj.AddressCity
            || !!obj.State && obj.State != '~' || !!obj.AddressState && obj.AddressState != '~'
            || !! obj.Zip || !!obj.PostalCode || !!obj.AddressZipCode;

        // If address is optional and all fields are blank, address is valid
        if (!required && !hasAddress) {
            return true;
        }

        // Otherwise validate required fields
        return this.validateString(obj.AddressLine1 || obj.Address1)
            && (!obj.AddressLine2 || this.validateString(obj.AddressLine2))
            && (!obj.Address2 || this.validateString(obj.Address2))
            && this.validateString(obj.City || obj.AddressCity)
            && this.validateString(obj.State || obj.AddressState)
            && this.validatePostalCode(obj.PostalCode || obj.Zip || obj.AddressZipCode, true);
    }

    validateBirthDate(date: string, years?: number): boolean {
        return this.validateDate(date)
            && moment(date).add(years || 15, 'years').isBefore(moment())
            && moment(date).add(100, 'years').add(1, 'days').isAfter(moment());
    }

    validateCompassId(compassId: string, required?: boolean): boolean {
        return !required
            || !!compassId && compassId.length > 0;
    }

    validateDate(date: string): boolean {
        if(!date || date.length != 10) return false; // assuming mask MM/DD/YYYY
        let mDate = moment(date);
        if(!mDate.isValid() || mDate.format('MM/DD/YYYY') != date) return false; // strictly validate "fuzzy" dates
        return true;
    }

    validateMinDate(date: string, min: string){
        return this.validateDate(date) && moment(date).isSameOrAfter(min);
    }

    validateMaxDate(date: string, max: string){
        return this.validateDate(date) && moment(date).isSameOrBefore(max);
    }

    // Email is optional, but if entered must be valid
    validateEmail(email: string, validationKey: string, required?: boolean): boolean {
        if (!required && !email) {
            return true;
        }
        if (email == this.validatedEmail[validationKey]) {
            return this.validEmail[validationKey]; // If email is unchanged, return previous validation result
        }
        let valid = this.regexConstants.Email.test(email);
        this.validatedEmail[validationKey] = email;
        this.validEmail[validationKey] = valid;
        return valid;
    }

    validateFutureDate(date: string): boolean {
        return this.validateDate(date)
            && moment(date).isAfter(moment().format('MM/DD/YYYY'));
    }

    validateNPI(npi: string, required?: boolean) {
        if (!required && !npi) {
            return true;
        }

        // Validate length, assuming mask '0000000000'
        if (!npi || npi.length != 10) {
            return false;
        }

        //Validate with Luhn algorithm
        let sum = 0;
        let num = '80840' + npi;
        for (let i = num.length - 2; i >= 0; i--) {
            let digit = parseInt(num[i]);
            if ((num.length - i) % 2 == 0) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }
            sum += digit;
        }
        let mod = sum % 10;
        let check = mod == 0 ? 0 : 10 - mod;
        return check == parseInt(npi.charAt(9));
    }

    validateNumber(value: string, min?: number, max?: number) {
        if (!value)
            return false;
        let val = parseFloat(value.toString().replace(/,/g, ''));
        if (isNaN(val)) {
            return false;
        }
        if (min !== undefined && val < min) {
            return false;
        }
        if (max !== undefined && val > max) {
            return false;
        }
        return true;
    }

    validatePastDate(date: string): boolean {
        return this.validateDate(date)
            && moment(date).isBefore(moment().format('MM/DD/YYYY'));
    }

    // Phone is optional, but if entered must be valid
    validatePhone(phone: string, validationKey: string, required?: boolean): boolean {
        if (!required && !phone) {
            return true;
        }
        if (phone == this.validatedPhone[validationKey]) {
            return this.validPhone[validationKey]; // If phone is unchanged, return previous validation result
        }
        let valid = this.regexConstants.Phone.test(phone);
        this.validatedPhone[validationKey] = phone;
        this.validPhone[validationKey] = valid;
        return valid;
    }

    validatePostalCode(postalCode: string, required?: boolean): boolean {
        return !required && !postalCode
            || !!postalCode && (postalCode.length == 5 || postalCode.length == 10); // Validates length, assuming mask '00000-0000'
    }

    validateSSN(ssn: string, required?: boolean): boolean {
        return !required && !ssn
            || !!ssn && ssn.replace(/\D/g, '').length == 9; // Assumes mask '000-00-0000'
    }

    validateString(value: string): boolean {
        return value != null
            && value != undefined
            && value.toString().trim().length > 0;
    }

    validateUrl(url: string | undefined, required?: boolean): boolean {
        if (!required && !url) {
            return true;
        } else if (!url) {
            return false;
        }
        return this.regexConstants.Url.test(url);
    }
    
    public isNumber(value: string | number): boolean
    {
        return !isNaN(Number(value.toString()));
    }
}