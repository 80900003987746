import { Injectable } from '@angular/core';

import { MGISConstants } from '../constants/mgis.constants';

declare var moment: any;

@Injectable()
export class CalculationService {

    constructor(
        private mgisConstants: MGISConstants
    ) { }

    calculatedAnnualSalary(salary: string, salaryType: string, numHoursPerWeek?: string): number { // Calculate annual salary based on type
        let salaryNum = this.calculatedSalary(salary);
        switch (salaryType) {
            case 'HO': return salaryNum * (numHoursPerWeek ? parseFloat(numHoursPerWeek) * 52 : 2080);
            case 'WE': return salaryNum * 52;
            case 'BW': return salaryNum * 26;
            case 'SM': return salaryNum * 24;
            case 'MO': return salaryNum * 12;
            case 'AN': return salaryNum;
            default: return 0;
        }
    }

    calculatedSalary(salary: string): number { // Parse salary string as number
        if (!salary) {
            return 0;
        }
        return parseFloat(salary.replace(/,/g, ''));
    }

    formatAddress(model: any) : string{
        let address = '';
        if(model.AddressLine1){
            address += model.AddressLine1;
        }
        if(model.AddressLine1 && (model.AddressLine2 || model.City || model.State || model.PostalCode)){
            address += '\r\n';
        }
        if(model.AddressLine2){
            address += model.AddressLine2;
        }
        if(model.AddressLine2 && (model.City || model.State || model.PostalCode)){
            address += '\r\n';
        }
        if(model.City){
            address += model.City;
        }
        if(model.City && (model.State || model.PostalCode)){
            address += ', ';
        }
        if(model.State){
            address += model.State;
        }
        if(model.State && model.PostalCode){
            address += ' ';
        }
        if(model.PostalCode){
            address += model.PostalCode;
        }
        return address;
    }

    getDate(date: string): string { // Get standard formatted date
        return moment(date).format('MM/DD/YYYY');
    }

    isToday(date: string): boolean { // Check whether given date is today
        return moment(date).isSame(moment(), 'd');
    }

    salaryTypeDisplay(salaryType: string, ) { // Get salary type display from code
        let type = this.mgisConstants.SalaryTypes.find(t => t.id == salaryType);
        return !!type ? type.text : null;
    }

    selectionDisplay(options: any[], num: string): string { // Get Name from Name/Num or text/id options list
        let opt = options.find(o => o.Num == num || o.id == num)
        return !!opt ? opt.Name || opt.text || opt.Num || opt.id : null; // Fall back to Num/id if no Name/text
    }

    showCalculatedAnnualSalary(salary: string, salaryType: string): boolean { // Check whether should show annual salary
        return !!salary 
            && !isNaN(parseFloat(salary))
            && !!salaryType
            && salaryType != 'AN';
    }

    stripCurrency(amount: string): string {
        return (amount || '').replace(/[$,]/g, '');
    }
}