
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { SecureHttp } from "../../auth/securehttp";
import { MemberCoverageRequestOptions, RateMemberRequestOptions } from "../../models/member.model";
import { Observable } from "rxjs";
import { Coverage, RatedCoverage } from "../../models/coverage.model";
import { environment } from "environments/environment";

@Injectable()
export class CoveragesService {

    constructor(private http: SecureHttp) {
    }

    available(options: MemberCoverageRequestOptions): Observable<Coverage[]> {
        let url = `api/coverages/available`;
        return this.http.post(url, options).pipe(
            map((response) => response as Coverage[]));
    }

    rates(options: RateMemberRequestOptions): Observable<RatedCoverage[]> {
        let url = `api/coverages/rates`;

        return this.http.post(url, options).pipe(
            map((response) => response as RatedCoverage[]));
    }

}