
import {map} from 'rxjs/operators';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService, 
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let url: string = state.url;

        return this.checkAuth(url);
    }

    checkAuth(url: string): Observable<boolean> {

        if (this.authService.isLoggedIn()) {

            return this.authService.queryCurrentUser().pipe(map(user => {
                if (!!user && user.MustResetPassword) {
                    this.authService.getResetToken().subscribe(token => {
                        this.router.navigate(['/auth/reset'], { queryParams: { token: token, email: user.Email, force: true } });
                        return false;
                    });
                }
                return !!user && user.Active && !user.MustResetPassword;
            }));
        } else {
            this.authService.redirectUrl = url;

            this.router.navigate(['/auth/login']);
            return of(false);
        }

    }
}
