export class Permissions {
    public static readonly Common =
        {
            Roles:
            {
                View: 'roles.view',
                Manage: 'roles.manage',
                Delete: 'roles.delete',
            },

            Users:
            {
                View: 'users.view',
                Manage: 'users.manage',
                Delete: 'users.delete',
            },

            Logs:
            {
                View: 'logs.view',
            },

            Sessions:
            {
                View: 'sessions.view',
            },
            Templates: 
            {
                Manage: 'templates.manage'
            }
        };

    public static readonly GroupBenefits =
        {
            AreaName: 'Group Benefits',
            Billing: {
                Payments: 'group-benefits.billing.payments',
                Statements: 'group-benefits.billing.statements',
            },
            Clients:
            {
                View: 'group-benefits.clients.view',
                Manage: 'group-benefits.clients.manage',
                Delete: 'group-benefits.clients.delete',
            },
            Enrollments: {
                View: 'group-benefits.enrollments.view'
            },
            Members:
            {
                View: 'group-benefits.members.view',
                Manage: 'group-benefits.members.manage',
                Enroll: 'group-benefits.members.enroll',
                Delete: 'group-benefits.members.delete',
            },
            Users: {
                Manage: 'group-benefits.users.manage'
            }
        };

    public static readonly Producers =
        {
            Agents:
            {
                View: 'producers.agents.view',
                Manage: 'producers.agents.manage',
                Delete: 'producers.agents.delete',
            },
            Banking:
            {
                Manage: 'producers.banking.manage',
            },
            Billing: {
                Payments: 'producers.billing.payments',
                Statements: 'producers.billing.statements',
            },
            BrokerFirms:
            {
                View: 'producers.broker-firms.view',
                Manage: 'producers.broker-firms.manage',
                Delete: 'producers.broker-firms.delete',
            },
            Clients:
            {
                View: 'producers.clients.view',
                Manage: 'producers.clients.manage',
                Delete: 'producers.clients.delete',
            },
            CommissionStatements: {
                View: 'producers.commission-statements.view'
            },
            TaxDocuments: 
            {
                View: 'producers.tax-documents.view'
            },
            ProducerInfo:
            {
                GroupName: 'Producer Info',

                All: 'producers.info.all',
            },
            Reporting:
            {
                Manage: 'producers.reporting.manage',
            },
            Users:
            {
                Manage: 'producers.users.manage',
            }
        };


    public static readonly SurplusLines =
        {
            AreaName: 'Surplus Lines',
            Applicants:
            {
                View: 'surplus-lines.applicants.view',
                Manage: 'surplus-lines.applicants.manage',
            },
            Opportunities:
            {
                View: 'surplus-lines.opportunities.view',
                Manage: 'surplus-lines.opportunities.manage',
            },
            Policies:
            {
                View: 'surplus-lines.policies.view',
                Manage: 'surplus-lines.policies.manage',
            }
        };
}
