import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return value;
		}
		let digits = value.replace(/\D/g, '');
		let phone = `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}-${digits.substr(6, 4)}`;
		if (digits.length > 10) {
			phone += ` ext. ${digits.substr(10, digits.length - 10)}`;
		}
		return phone;
	}
}