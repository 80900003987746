import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { PopoverModule } from "ngx-smart-popover";

import { CollapseMenuComponent } from "./collapse-menu/collapse-menu.component";
import { FullScreenComponent } from "./full-screen/full-screen.component";

import { ActivitiesComponent } from "./activities/activities.component";
import { ActivitiesMessageComponent } from "./activities/activities-message/activities-message.component";
import { ActivitiesNotificationComponent } from "./activities/activities-notification/activities-notification.component";
import { ActivitiesTaskComponent } from "./activities/activities-task/activities-task.component";
import { HeaderComponent } from "./header.component";

import { UtilsModule } from "../../utils/utils.module";
import { UserModule } from "../../user/user.module";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";


@NgModule({
  imports: [
    CommonModule,

    FormsModule,

    RouterModule,

    BsDropdownModule,

    UtilsModule, UserModule, PopoverModule,
  ],
  declarations: [
    ActivitiesMessageComponent,
    ActivitiesNotificationComponent,
    ActivitiesTaskComponent,
    FullScreenComponent,
    CollapseMenuComponent,
    ActivitiesComponent,
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
