import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';

// constants
import { MGISConstants } from './constants/mgis.constants';
import { PasswordConstants } from './constants/password.constants';
import { RegexConstants } from './constants/regex.constants';
import { AzureConstants } from './constants/azure.constants';

// directives
import { InputAutosizeDirective } from './directives/input-autosize.directive';
import { InputMaskDirective } from './directives/input-mask.directive';
import { InputMoneyMaskDirective } from './directives/input-money-mask.directive';
import { LazyLoadImageDirective } from './directives/lazy-load-image.directive';
import { TextareaAutosizeDirective } from './directives/textarea-autosize.directive';
import { DndDirective } from './directives/dnd.directive';

// pipes
import { LastFourPipe } from "./pipes/last-four.pipe";
import { ListPipe } from "./pipes/list.pipe";
import { MaskSSNPipe } from "./pipes/mask-ssn.pipe";
import { NamesPipe } from "./pipes/names.pipe";
import { KeysPipe } from "./pipes/keys.pipe";
import { SelectListPipe } from "./pipes/select-list.pipe";

// services
import { CalculationService } from "./services/calculation.service";
import { ToastService } from "./services/toast.service";
import { ValidationService } from "./services/validation.service";
import { PhonePipe } from 'app/common/pipes/phone.pipe';
import { IPService } from './services/ip.service';
import { AddressesService } from './services/addresses.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { PermissionsService } from './services/permissions.service';
import { ConfigService } from 'app/admin/config/config.service';
import { StripeService } from './services/stripe.service';
import { FileService } from './services/file.service';
import { SharePointTokenService } from './services/sharepoint-token.service'

// components
import { AddressesComponent } from './components/addresses/addresses.component';
import { AddressComponent } from './components/addresses/address/address.component';
import { LoadingEllipsisComponent } from './components/loading-ellipsis/loading-ellipsis.component';
import { DragDropComponent } from './components/drag-drop/drag-drop.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalModule.forRoot()
    ],
    declarations: [
        InputAutosizeDirective,
        InputMaskDirective,
        InputMoneyMaskDirective,
        LastFourPipe,
        LazyLoadImageDirective,
        ListPipe,
        KeysPipe,
        MaskSSNPipe,
        NamesPipe,
        PhonePipe,
        SelectListPipe,
        TextareaAutosizeDirective,
        AddressesComponent,
        AddressComponent,
        LoadingEllipsisComponent,
        DragDropComponent,
        DndDirective
    ],
    exports: [
        CommonModule,
        InputAutosizeDirective,
        InputMaskDirective,
        InputMoneyMaskDirective,
        KeysPipe,
        LastFourPipe,
        LazyLoadImageDirective,
        ListPipe,
        MaskSSNPipe,
        NamesPipe,
        PhonePipe,
        SelectListPipe,
        TextareaAutosizeDirective,
        AddressesComponent,
        AddressComponent,
        DragDropComponent,
        LoadingEllipsisComponent
    ],
    providers: [
        AzureConstants,
        MGISConstants,
        PasswordConstants,
        RegexConstants,
        AddressesService,
        CalculationService,
        ConfigService,
        ToastService,
        ValidationService,
        IPService,
        PermissionsService,
        StripeService,
        FileService,
        PhonePipe,
        LastFourPipe,
        TitleCasePipe,
        SharePointTokenService,
    ]
})

export class MGISCommonModule { }