import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { User } from "../../../models/user.model";

import { AuthService } from '../../../auth/auth.service';
import { NotificationService } from "../../utils/notification.service";
import { SubscriberBase } from "app/common/base/subscriber.base";
import { MGISConstants } from 'app/common/constants/mgis.constants';
import { BrokerFirm } from '../../../models/broker-firm.model';
import { DashboardService } from 'app/dashboard/dashboard.service';
import { GroupBenefitsClient } from 'app/models/group-benefits-client.model';

declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: 'header.component.html'
})
export class HeaderComponent extends SubscriberBase implements OnInit {

  query = null;
  searchActive = false;
  searchMobileActive = false;
  searchPlaceholder = '';
  interruptToggleSearch = false;
  isMGISSuperUser = false;
  isMGISAccountManager = false;
  isMGISBrokerServices = false;
  isGroupBenefitsAdmin = false;
  isSurplusLinesApplicant = false;
  isSurplusLinesBusinessContact = false;
  isSurplusLinesInsured = false;
  isSurplusLinesPolicyOwner = false;
  isProducerAdmin = false;

  isLoggedIn = false;
  isGroupBenefitsPortal = false;
  isProducerPortal = false;

  currentUser: User;
  selectedClient: GroupBenefitsClient;
  selectedProducer: BrokerFirm;
  showPortalSelection: boolean;

  accountName: string;

  get canSelectFirm() {
    return this.isProducerPortal && this.currentUser && this.currentUser.BrokerFirms && this.currentUser.BrokerFirms.length > 1;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private notificationService: NotificationService,
    private mgisConstants: MGISConstants,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(this.authService.queryCurrentUser().subscribe(user => this.currentUser = user));

    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.MGISSuperUser).subscribe(isInRole => this.isMGISSuperUser = isInRole));
    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.MGISAccountManager).subscribe(isInRole => this.isMGISAccountManager = isInRole));
    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.MGISBrokerServices).subscribe(isInRole => this.isMGISBrokerServices = isInRole));
    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.GroupBenefitsAdmin).subscribe(isInRole => this.isGroupBenefitsAdmin = isInRole));
    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesApplicant).subscribe(isInRole => this.isSurplusLinesApplicant = isInRole));
    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesBusinessContact).subscribe(isInRole => this.isSurplusLinesBusinessContact = isInRole));
    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesInsured).subscribe(isInRole => this.isSurplusLinesInsured = isInRole));
    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesPolicyOwner).subscribe(isInRole => this.isSurplusLinesPolicyOwner = isInRole));
    this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.ProducerAdmin).subscribe(isInRole => this.isProducerAdmin = isInRole));

    this.authService.queryCurrentUserPortals().subscribe(portals => {
      this.showPortalSelection = portals.length > 1;
    });

    this.isLoggedIn = this.authService.isLoggedIn();
    this.authService.getPortal().subscribe(portal => {
      this.isGroupBenefitsPortal = portal == this.mgisConstants.Portals.GroupBenefits.Name;
      this.isProducerPortal = portal == this.mgisConstants.Portals.Producer.Name;

      if (this.isGroupBenefitsPortal) {
        this.subscriptions.push(this.authService.getSelectedClient().subscribe(client => {
          this.selectedClient = client;
          this.dashboardService.getGroupBenefitsInfo(this.selectedClient.AccountNum).subscribe(info => this.accountName = info && info.AccountName);
        }));
      }

      if (this.isProducerPortal) {
        this.subscriptions.push(this.authService.getSelectedProducer().subscribe(producer => this.selectedProducer = producer));
      }
    });
  }

  toggleSearch(search?: boolean) {
    if (!this.interruptToggleSearch) {
      this.searchActive = !this.searchActive;
      if (this.searchActive) {
        $('#search-fld').focus();
        setTimeout(() => { this.searchPlaceholder = 'Find...' }, 100);
      } else {
        $('#search-fld').blur();
        setTimeout(() => {
          this.searchPlaceholder = '';
          this.query = $('#search-fld').val();
          $('#search-fld').val('');
          if (search && this.query) {
            this.search();
          }
        }, 100);
      }
      this.interruptToggleSearch = true;
      setTimeout(() => { this.interruptToggleSearch = false; }, 100);
    }
  }

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  search() {
    this.router.navigate(['/miscellaneous/search']);
  }

  logout() {
    this.notificationService.smartMessageBox({
      title: "<i class='fa fa-sign-out txt-color-primary'></i> Logout?",
      content: "You can improve your security further after logging out by closing this opened browser",
      buttons: '[No][Yes]'

    }, (ButtonPressed) => {
      if (ButtonPressed == "Yes") {
        this.authService.logout();
      }
    });
  }
}
