export class Roles {
    public static readonly MGISAdmin =
        {
            SuperUser: 'MGIS Administration | Super User',
            AccountManager: 'MGIS Administration | Account Manager',
            BrokerServices: 'MGIS Administration | Broker Services'
        };

    public static readonly GroupBenefits =
        {
            Admin: 'Group Benefits Portal | Group Admin'
        };

    public static readonly Producers =
        {
            Admin: 'Producer Portal | Producer Admin'
        };


    public static readonly SurplusLines =
        {
            Applicant: 'Surplus Lines Portal | Applicant',
            BusinessContact: 'Surplus Lines Portal | Business Contact',
            Insured: 'Surplus Lines Portal | Insured',
            PolicyOwner: 'Surplus Lines Portal | Policy Owner'
        };
}
