
import { Injectable } from '@angular/core';
declare var $: any;

@Injectable()
export class ToastService {

    currentlyDisplayed = [];

    message(content?: string, title?: string, duration?: number, color?: string, icon?: string) {
        let messageTitle = title || '';
        let messageContent = content || '';
        let timeout = duration || 6000;
        let icn = icon || 'fa fa-info-circle';
        let clr = color || '#6483bb';

        // fix for missing, obsolete jQuery size function         
        if (!$.fn.size) {
            $.fn.size = function () {
                return $(this[0]).length;
            }
        }
        let notification = {
            title: messageTitle,
            content: messageContent,
            color: clr,
            iconSmall: icn,
            timeout: timeout
        };

        // prevent duplicate notifications;
        if (!this.currentlyDisplayed.find(n => n.title == notification.title && n.content == notification.content && n.color == notification.color)) {
            let _this = this;
            _this.currentlyDisplayed.push(notification);
            $.smallBox(notification);
            setTimeout(
                () => _this.currentlyDisplayed.splice(_this.currentlyDisplayed.indexOf(notification), 1), 
                notification.timeout
            );
        }
    }


    success(content?: string, title?: string, duration?: number) {
        let messageTitle = title || 'Success';
        let messageContent = content || 'Submitted Successfully.';

        this.message(messageContent, messageTitle, duration, '#4caf50', 'fa fa-thumbs-up');
    }

    warn(content?: string, title?: string, duration?: number) {
        let messageTitle = title || 'Warning';
        let messageContent = content || '';

        this.message(messageContent, messageTitle, duration, '#ffcc00', 'fa fa-exclamation-triangle');
    }

    error(content?: string, title?: string, duration?: number) {
        let messageTitle = title || 'Failure';
        let messageContent = content || 'Operation was unsuccessful.';

        this.message(messageContent, messageTitle, duration, '#c26565', 'fa fa-exclamation-triangle');
    }

    validation() {
        this.message('One or more fields is invalid.', 'Validation', 4000, '#ffc107', 'fa fa-exclamation-triangle')
    }
}