<aside id="left-panel">

    <!-- User info -->
    <!--<sa-login-info></sa-login-info>-->
    <!-- end user info -->
    <!--<img id="profile-picture" src="assets/img/abcmed/profile.png" *ngIf="user" />-->
    <!--<img id="profile-picture" src="assets/img/cover.png" *ngIf="!user" />-->

    <button class="btn btn-link toggle-menu" (click)="toggleMenu()">
        <i class="fa fa-angle-right"></i>
        <i class="fa fa-angle-left"></i>
    </button>

    <nav>
        <!-- NOTE: Notice the gaps after each icon usage <i></i>..
        Please note that these links work a bit different than
        traditional href="" links. See documentation for details.
        -->

        <ul saSmartMenu>

            <li routerLinkActive="active">
                <a routerLink="/dashboard" title="Home">
                    <i class="fa fa-lg fa-fw fa-home"></i>
                    <span class="menu-item-parent">Home</span>
                </a>
            </li>
            <li *ngIf="canViewMembers && isGroupBenefitsPortal " [hidden]="!isNotSelfAdmin">
                <a href="#" title="Members">
                    <i class="fa fa-lg fa-fw fa-users"></i>
                    <span class="menu-item-parent">Members</span>
                </a>
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/group-benefits/members/list" title="Manage Members">Manage</a>
                    </li>
                    <li routerLinkActive="active" *ngIf="canManageMembers">
                        <a routerLink="/group-benefits/members/enroll" title="Enroll Member">Enroll</a>
                    </li>
                    <li routerLinkActive="active" *ngIf="canManageMembers">
                        <a routerLink="/group-benefits/members/salary-editor" title="Edit Multiple Members' Salaries">
                            Salary
                            Editor
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/group-benefits/members/updates"
                            [queryParams]="{submittedDateStart: todayMinus30, submittedDateEnd: today}"
                            title="Pending & Reviewed Member Change Requests">Change Request History</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="(canViewBillingStatements || canViewBillingPayments) && (isGroupBenefitsPortal || isSurplusLinesPortal)"
                [hidden]="isSurplusLinesPortal && !hasBillingStatements">
                <a href="#" title="Billing">
                    <span class="fa-stack fa-fw">
                        <i class="fa fa-file fa-stack-2x"></i>
                        <i class="fa fa-usd fa-stack-1x txt-color-white" style="margin-top: 2px;"></i>
                    </span>
                    <span class="menu-item-parent">Billing</span>
                </a>
                <ul>
                    <li routerLinkActive="active" *ngIf="canViewBillingStatements">
                        <a routerLink="/billing/statements" title="Statements">Statements</a>
                    </li>
                    <li routerLinkActive="active" *ngIf="canViewBillingPayments">
                        <a routerLink="/payments/new" title="Pay Bills Online">Pay Bills Online</a>
                    </li>
                    <li routerLinkActive="active" *ngIf="canViewBillingPayments">
                        <a routerLink="/payments/history" title="Payment History">Payment History</a>
                    </li>
                    <li routerLinkActive="active" *ngIf="canViewBillingPayments">
                        <a routerLink="/bank-accounts" title="Manage Payment Methods">Payment Methods</a>
                    </li>
                    <li routerLinkActive="active" *ngIf="canViewBillingPayments">
                        <a routerLink="/billing/accounts/preferences" title="Billing Preferences">Billing
                            Preferences</a>
                    </li>
                </ul>
            </li>
            <li
                *ngIf="(isGroupBenefitsAdmin || isMGISAccountManager || isMGISSuperUser || canViewMembers) && isGroupBenefitsPortal">
                <a href="#" title="Documents">
                    <i class="fa fa-lg fa-fw fa-file-text"></i>
                    <span class="menu-item-parent">Documents</span>
                </a>
                <ul>
                    <li *ngIf="(isGroupBenefitsAdmin || isMGISAccountManager || isMGISSuperUser || canViewMembers) && isGroupBenefitsPortal"
                        routerLinkActive="active">
                        <a routerLink="/documents/policy" title="Policy Forms">Policy Forms</a>
                    </li>
                    <li *ngIf="(isGroupBenefitsAdmin || isMGISAccountManager || isMGISSuperUser || canViewMembers) && isGroupBenefitsPortal"
                        routerLinkActive="active">
                        <a routerLink="/documents/contracts" title="Contracts & Booklets">Contracts & Booklets</a>
                    </li>
                    <li *ngIf="(isGroupBenefitsAdmin || isMGISAccountManager || isMGISSuperUser || canViewMembers) && isGroupBenefitsPortal"
                        routerLinkActive="active">
                        <a routerLink="/documents/benefits" title="Benefit Summaries">Benefit Summaries</a>
                    </li>
                    <li *ngIf="(isGroupBenefitsAdmin || isMGISAccountManager || isMGISSuperUser || canViewMembers) && isGroupBenefitsPortal"
                        routerLinkActive="active">
                        <a routerLink="/documents/brochures" title="Brochures & Flyers">Brochures & Flyers</a>
                    </li>
                    <li *ngIf="(isGroupBenefitsAdmin || isMGISAccountManager || isMGISSuperUser || canViewMembers) && isGroupBenefitsPortal"
                        routerLinkActive="active" [hidden]="!isNotSelfAdmin">
                        <a routerLink="/documents/census" title="Census & Premium Deductions">Census & Premium
                            Deductions</a>
                    </li>
                    <li *ngIf="(isGroupBenefitsAdmin || isMGISAccountManager || isMGISSuperUser || canViewMembers) && isGroupBenefitsPortal"
                        routerLinkActive="active">
                        <a routerLink="/documents/other" title="Other Documents">Other</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="(isMGISSuperUser || isMGISAccountManager || isMGISBrokerServices) && isMGISAdmin"
                routerLinkActive="active">
                <a routerLink="/admin/users" title="Manage Users">
                    <i class="fa fa-lg fa-fw fa-users"></i>
                    <span class="menu-item-parent">Users</span>
                </a>
            </li>
            <li *ngIf="isMGISSuperUser && isMGISAdmin" routerLinkActive="active">
                <a routerLink="/admin/documents" title="Documents">
                    <i class="fa fa-lg fa-fw fa-file-text"></i>
                    <span class="menu-item-parent">Documents</span>
                </a>
            </li>
            <!-- <li *ngIf="isMGISSuperUser && isMGISAdmin" routerLinkActive="active">
              <a routerLink="/admin/roles" title="Manage Roles">
                <i class="fa fa-lg fa-fw fa-id-badge"></i>
                <span class="menu-item-parent">Roles</span>
              </a>
            </li> -->
            <li *ngIf="(isMGISSuperUser || isMGISAccountManager) && isMGISAdmin" routerLinkActive="active">
                <a routerLink="/admin/clients" title="Manage Clients">
                    <i class="fa fa-lg fa-fw fa-building"></i>
                    <span class="menu-item-parent">Clients</span>
                </a>
            </li>
            <li *ngIf="(isMGISSuperUser || isMGISBrokerServices) && isMGISAdmin" routerLinkActive="active">
                <a routerLink="/admin/broker-firms" title="Manage Broker Firms">
                    <i class="fa fa-lg fa-fw fa-briefcase"></i>
                    <span class="menu-item-parent">Broker Firms</span>
                </a>
            </li>
            <li *ngIf="isMGISSuperUser && isMGISAdmin">
                <a routerLink="/admin/questionnaire-template" title="Templates">
                    <i class="fa fa-lg fa-fw fa-pencil-square-o"></i>
                    <span class="menu-item-parent">Templates</span>
                </a>
            </li>
            <li *ngIf="isMGISSuperUser && isMGISAdmin" routerLinkActive="active">
                <a routerLink="/admin/logs" title="View Portal Activity Logs">
                    <i class="fa fa-lg fa-fw fa-list"></i>
                    <span class="menu-item-parent">Logs</span>
                </a>
            </li>
            <li *ngIf="isMGISSuperUser && isMGISAdmin" routerLinkActive="active">
                <a routerLink="/admin/activity" title="Login Activity">
                    <i class="fa fa-lg fa-fw fa-history"></i>
                    <span class="menu-item-parent">Login Activity</span>
                </a>
            </li>
            <li *ngIf="isMGISSuperUser && isMGISAdmin" routerLinkActive="active">
                <a routerLink="/admin/config" title="App Settings">
                    <i class="fa fa-lg fa-fw fa-cog"></i>
                    <span class="menu-item-parent">App Settings</span>
                </a>
            </li>
            <li *ngIf="isMGISSuperUser && isMGISAdmin" routerLinkActive="active">
                <a routerLink="/release-notes" title="Release Notes">
                    <i class="fa fa-lg fa-fw fa-file-text"></i>
                    <span class="menu-item-parent">Release Notes</span>
                </a>
            </li>

            <li *ngIf="(isSurplusLinesAdmin || isMGISSuperUser) && isSurplusLinesPortal" routerLinkActive="active">
                <a routerLink="/surplus-lines/invitations" title="Invite Surplus Lines Applicants">
                    <i class="fa fa-lg fa-fw fa-user-plus"></i>
                    <span class="menu-item-parent">Invite</span>
                </a>
            </li>
            <li *ngIf="(isSurplusLinesPolicyOwner || isSurplusLinesApplicant || isMGISSuperUser) && isSurplusLinesPortal"
                routerLinkActive="active">
                <a routerLink="/surplus-lines/profile" title="Your Personal Information">
                    <i class="fa fa-lg fa-fw fa-user"></i>
                    <span class="menu-item-parent">Profile</span>
                </a>
            </li>
            <li *ngIf="(isSurplusLinesPolicyOwner || isMGISSuperUser) && isSurplusLinesPortal"
                routerLinkActive="active">
                <a routerLink="/surplus-lines/policies" title="Policies">
                    <i class="fa fa-lg fa-fw fa-file-text"></i>
                    <span class="menu-item-parent">Policies</span>
                </a>
            </li>
            <li *ngIf="canViewProducers && isProducerPortal">
                <a href="#" title="Producers">
                    <i class="fa fa-lg fa-fw fa-briefcase"></i>
                    <span class="menu-item-parent">Producers</span>
                </a>
                <ul>
                    <li routerLinkActive="active" routerLink="/broker-firm/producers/list"
                        *ngIf="agentTypeCode != 'BR'">
                        <a>{{canManageProducers ? 'Manage' : 'View'}}</a>
                    </li>
                    <li routerLinkActive="active" *ngIf="canManageProducers"
                        routerLink="/broker-firm/producers/updates">
                        <a>Change Request History</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="canViewClients && isProducerPortal" routerLinkActive="active"
                routerLink="/broker-firm/producers/clients">
                <a title="Clients">
                    <i class="fa fa-lg fa-fw fa-users"></i>
                    <span class="menu-item-parent">Clients</span>
                </a>
            </li>
            <li *ngIf="isProducerPortal">
                <a routerLink="/documents/producer" title="Producer Documents">
                    <i class="fa fa-lg fa-fw fa-file-text"></i>
                    <span class="menu-item-parent">Documents</span>
                </a>
            </li>
            <li *ngIf="isProducerPortal">
                <a href="#" title="Manage Preferences">
                    <i class="fa fa-lg fa-fw fa-cogs"></i>
                    <span class="menu-item-parent">Preferences</span>
                </a>
                <ul>
                    <li routerLinkActive="active" routerLink="/preferences/general">
                        <a>Email Notifications</a>
                    </li>
                    <li routerLinkActive="active" routerLink="/preferences/commission" *ngIf="canManageProducerBanking">
                        <a>Payment Method</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="isGroupBenefitsPortal" routerLinkActive="active">
                <a routerLink="/preferences/general" title="Preferences">
                    <i class="fa fa-lg fa-fw fa-cogs"></i>
                    <span class="menu-item-parent">Preferences</span>
                </a>
            </li>
            <li *ngIf="(canViewProducerReports || canViewCommissionStatements || canViewTaxDocuments) && isProducerPortal">
                <a href="#" title="Reports">
                    <i class="fa fa-lg fa-fw fa-area-chart"></i>
                    <span class="menu-item-parent">Reports</span>
                </a>
                <ul>
                    <li *ngIf="canViewCommissionStatements" routerLink="/broker-firm/commission-statements"
                        routerLinkActive="active">
                        <a>Commissions Statements</a>
                    </li>
                </ul>
                <ul>
                    <li *ngIf="canViewTaxDocuments" routerLink="/broker-firm/tax-documents"
                        routerLinkActive="active">
                        <a>Tax Documents</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="canViewGroupBenefitsUsers && isGroupBenefitsPortal" routerLinkActive="active"
                routerLink="/group-benefits/users">
                <a title="User Management">
                    <i class="fa fa-lg fa-fw fa-user"></i>
                    <span class="menu-item-parent">User Management</span>
                </a>
            </li>
            <li *ngIf="canViewProducerUsers && isProducerPortal" routerLinkActive="active"
                routerLink="/broker-firm/users">
                <a title="User Management">
                    <i class="fa fa-lg fa-fw fa-user"></i>
                    <span class="menu-item-parent">User Management</span>
                </a>
            </li>
            <!--<li *ngIf="(isProducerAdmin || isMGISBrokerServices || isMGISSuperUser) && isProducerPortal" routerLinkActive="active">
              <a routerLink="/producer/licenses-appointments" title="Manage Licenses & Appointments">
                <i class="fa fa-lg fa-fw fa-calendar"></i>
                <span class="menu-item-parent">Licenses & Appointments</span>
              </a>
            </li>-->
        </ul>
    </nav>

    <div id="aside-info" *ngIf="asideInfo">
        <div *ngFor="let info of asideInfo">
            <h5>{{info.Title}}</h5>
            <p class="light" *ngFor="let line of info.Info">
                {{line}}
            </p>
        </div>
    </div>

</aside>