import { catchError, map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { LocalStorageService } from 'angular-2-local-storage'

@Injectable()
export class SharePointTokenService {
  constructor(private http: HttpClient, private store: LocalStorageService) {}
  getAuthToken(): Observable<any> {
    let url = `api/documents/sharepoint-token`
    let token: any = this.store.get('authData')
    let headers = new HttpHeaders()
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded')
    headers = headers.set('Authorization', `Bearer ${token.access_token}`)
    return this.http.post(url, null, { headers }).pipe(
      map((res) => {
        return res
      }),
      catchError((e) => e.message)
    )
  }
}
