
import {map} from 'rxjs/operators';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { MGISConstants } from '../../common/constants/mgis.constants';

@Injectable()
export class GroupBenefitsAdminGuard implements CanActivate {

    roles = [
        this.mgisConstants.Roles.MGISSuperUser,
        this.mgisConstants.Roles.MGISAccountManager,
        this.mgisConstants.Roles.GroupBenefitsAdmin
    ];

    constructor(
        private authService: AuthService, 
        private mgisConstants: MGISConstants,
        private router: Router
        ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let url: string = state.url;

        return this.checkAuth(url);
    }

    checkAuth(url: string): Observable<boolean> {

        if (this.authService.isLoggedIn()) {

            return this.authService.queryCurrentUser().pipe(map(user => {
                return !!user && user.Roles.some(r => this.roles.indexOf(r) >= 0);
            }));
        }

        this.authService.redirectUrl = url;

        this.router.navigate(['/auth/login']);
        return of(false);
    }
}
