
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SecureHttp } from 'app/auth/securehttp';
import { Observable, of } from 'rxjs';

@Injectable()
export class ConfigService {

    private config: any;

    constructor(
        private http: HttpClient,
        private secureHttp: SecureHttp
    ) {
    }

    getConfig(refresh?: boolean): Observable<any> {
        if (this.config && !refresh) {
            return of(this.config);
        }
        return this.http.get(`api/config`).pipe(
            map(response => {
                this.config = response;
                return this.config;
            }));
    }

    getAdminConfig(): Observable<any> {
        return this.http.get('api/config/admin')
            ;
    }

    getCurrentOutage(): Observable<any> {
        return this.http.get('api/config/outages/current')
            ;
    }

    getPlannedOutages(): Observable<any> {
        return this.http.get('api/config/outages')
            ;
    }

    savePlannedOutage(outage): Observable<any> {
        return (outage.Id ? this.http.put(`api/config/outages/${outage.Id}`, outage) : this.http.post('api/config/outages', outage))
            ;
    }

    deletePlannedOutage(outage): Observable<any> {
        return this.http.delete(`api/config/outages/${outage.Id}`);
    }

    runLoadTest(rate: number, duration: number): Observable<any> {
        let params = new HttpParams()
        params = params.set('rate', rate.toString())
        params = params.set('duration', duration.toString())
        return this.secureHttp.get(`api/test/compass-load`, { params: params });
    }
}
