import { Injectable } from '@angular/core'

@Injectable()
export class MobileServiceService {

  constructor() {}

  ngOnInit() {
    this.checkMobile()
  }

  checkMobile() {
    if (window.innerWidth < 599) {
      return true
    } else {
      return false
    }
  }
}
